// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

.form-control-validation(@text-color: @brand-primary; @border-color: @gray-light; @background-color: @inverse) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    color: @text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    color: @text-color;
    border-color: @border-color;
    box-shadow: none;
    .placeholder(@text-color);

    &:focus {
      border-color: @border-color;
      box-shadow: none;
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: @text-color;
    border-color: @border-color;
    background-color: @background-color;
  }
  .form-control-feedback {
    color: @text-color;
  }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

.form-control-focus(@color: @brand-secondary) {
  .form-group.focus &,
  &:focus {
    border-color: @color;
    outline: 0;
    box-shadow: none;
  }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

.input-size(@input-height; @padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius: @border-radius-large) {
  height: @input-height;
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;

  select& {
    height: @input-height;
    line-height: @input-height;
  }

  textarea&,
  select[multiple]& {
    height: auto;
  }
}

// Reset rounded corners for form controls
//

.form-controls-corners-reset() {
  .input-group .form-control:first-child,
  .input-group-addon:first-child,
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .dropdown-toggle,
  .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    .border-right-radius(0);
  }
  .input-group .form-control:last-child,
  .input-group-addon:last-child,
  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .dropdown-toggle,
  .input-group-btn:first-child > .btn:not(:first-child) {
    .border-left-radius(0);
  }
}
