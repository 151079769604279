@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-black.eot');
  src: url('../fonts/lato/lato-black.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-black.woff') format('woff'), url('../fonts/lato/lato-black.ttf') format('truetype'), url('../fonts/lato/lato-black.svg#latoblack') format('svg');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-bold.eot');
  src: url('../fonts/lato/lato-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-bold.woff') format('woff'), url('../fonts/lato/lato-bold.ttf') format('truetype'), url('../fonts/lato/lato-bold.svg#latobold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-bolditalic.eot');
  src: url('../fonts/lato/lato-bolditalic.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-bolditalic.woff') format('woff'), url('../fonts/lato/lato-bolditalic.ttf') format('truetype'), url('../fonts/lato/lato-bolditalic.svg#latobold-italic') format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-italic.eot');
  src: url('../fonts/lato/lato-italic.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-italic.woff') format('woff'), url('../fonts/lato/lato-italic.ttf') format('truetype'), url('../fonts/lato/lato-italic.svg#latoitalic') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-light.eot');
  src: url('../fonts/lato/lato-light.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-light.woff') format('woff'), url('../fonts/lato/lato-light.ttf') format('truetype'), url('../fonts/lato/lato-light.svg#latolight') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-regular.eot');
  src: url('../fonts/lato/lato-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-regular.woff') format('woff'), url('../fonts/lato/lato-regular.ttf') format('truetype'), url('../fonts/lato/lato-regular.svg#latoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Flat-UI-Icons';
  src: url('../fonts/glyphicons/flat-ui-icons-regular.eot');
  src: url('../fonts/glyphicons/flat-ui-icons-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons/flat-ui-icons-regular.woff') format('woff'), url('../fonts/glyphicons/flat-ui-icons-regular.ttf') format('truetype'), url('../fonts/glyphicons/flat-ui-icons-regular.svg#flat-ui-icons-regular') format('svg');
}
[class^="fui-"],
[class*="fui-"] {
  font-family: 'Flat-UI-Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fui-triangle-up:before {
  content: "\e600";
}
.fui-triangle-down:before {
  content: "\e601";
}
.fui-triangle-up-small:before {
  content: "\e602";
}
.fui-triangle-down-small:before {
  content: "\e603";
}
.fui-triangle-left-large:before {
  content: "\e604";
}
.fui-triangle-right-large:before {
  content: "\e605";
}
.fui-arrow-left:before {
  content: "\e606";
}
.fui-arrow-right:before {
  content: "\e607";
}
.fui-plus:before {
  content: "\e608";
}
.fui-cross:before {
  content: "\e609";
}
.fui-check:before {
  content: "\e60a";
}
.fui-radio-unchecked:before {
  content: "\e60b";
}
.fui-radio-checked:before {
  content: "\e60c";
}
.fui-checkbox-unchecked:before {
  content: "\e60d";
}
.fui-checkbox-checked:before {
  content: "\e60e";
}
.fui-info-circle:before {
  content: "\e60f";
}
.fui-alert-circle:before {
  content: "\e610";
}
.fui-question-circle:before {
  content: "\e611";
}
.fui-check-circle:before {
  content: "\e612";
}
.fui-cross-circle:before {
  content: "\e613";
}
.fui-plus-circle:before {
  content: "\e614";
}
.fui-pause:before {
  content: "\e615";
}
.fui-play:before {
  content: "\e616";
}
.fui-volume:before {
  content: "\e617";
}
.fui-mute:before {
  content: "\e618";
}
.fui-resize:before {
  content: "\e619";
}
.fui-list:before {
  content: "\e61a";
}
.fui-list-thumbnailed:before {
  content: "\e61b";
}
.fui-list-small-thumbnails:before {
  content: "\e61c";
}
.fui-list-large-thumbnails:before {
  content: "\e61d";
}
.fui-list-numbered:before {
  content: "\e61e";
}
.fui-list-columned:before {
  content: "\e61f";
}
.fui-list-bulleted:before {
  content: "\e620";
}
.fui-window:before {
  content: "\e621";
}
.fui-windows:before {
  content: "\e622";
}
.fui-loop:before {
  content: "\e623";
}
.fui-cmd:before {
  content: "\e624";
}
.fui-mic:before {
  content: "\e625";
}
.fui-heart:before {
  content: "\e626";
}
.fui-location:before {
  content: "\e627";
}
.fui-new:before {
  content: "\e628";
}
.fui-video:before {
  content: "\e629";
}
.fui-photo:before {
  content: "\e62a";
}
.fui-time:before {
  content: "\e62b";
}
.fui-eye:before {
  content: "\e62c";
}
.fui-chat:before {
  content: "\e62d";
}
.fui-home:before {
  content: "\e62e";
}
.fui-upload:before {
  content: "\e62f";
}
.fui-search:before {
  content: "\e630";
}
.fui-user:before {
  content: "\e631";
}
.fui-mail:before {
  content: "\e632";
}
.fui-lock:before {
  content: "\e633";
}
.fui-power:before {
  content: "\e634";
}
.fui-calendar:before {
  content: "\e635";
}
.fui-gear:before {
  content: "\e636";
}
.fui-bookmark:before {
  content: "\e637";
}
.fui-exit:before {
  content: "\e638";
}
.fui-trash:before {
  content: "\e639";
}
.fui-folder:before {
  content: "\e63a";
}
.fui-bubble:before {
  content: "\e63b";
}
.fui-export:before {
  content: "\e63c";
}
.fui-calendar-solid:before {
  content: "\e63d";
}
.fui-star:before {
  content: "\e63e";
}
.fui-star-2:before {
  content: "\e63f";
}
.fui-credit-card:before {
  content: "\e640";
}
.fui-clip:before {
  content: "\e641";
}
.fui-link:before {
  content: "\e642";
}
.fui-tag:before {
  content: "\e643";
}
.fui-document:before {
  content: "\e644";
}
.fui-image:before {
  content: "\e645";
}
.fui-facebook:before {
  content: "\e646";
}
.fui-youtube:before {
  content: "\e647";
}
.fui-vimeo:before {
  content: "\e648";
}
.fui-twitter:before {
  content: "\e649";
}
.fui-spotify:before {
  content: "\e64a";
}
.fui-skype:before {
  content: "\e64b";
}
.fui-pinterest:before {
  content: "\e64c";
}
.fui-path:before {
  content: "\e64d";
}
.fui-linkedin:before {
  content: "\e64e";
}
.fui-google-plus:before {
  content: "\e64f";
}
.fui-dribbble:before {
  content: "\e650";
}
.fui-behance:before {
  content: "\e651";
}
.fui-stumbleupon:before {
  content: "\e652";
}
.fui-yelp:before {
  content: "\e653";
}
.fui-wordpress:before {
  content: "\e654";
}
.fui-windows-8:before {
  content: "\e655";
}
.fui-vine:before {
  content: "\e656";
}
.fui-tumblr:before {
  content: "\e657";
}
.fui-paypal:before {
  content: "\e658";
}
.fui-lastfm:before {
  content: "\e659";
}
.fui-instagram:before {
  content: "\e65a";
}
.fui-html5:before {
  content: "\e65b";
}
.fui-github:before {
  content: "\e65c";
}
.fui-foursquare:before {
  content: "\e65d";
}
.fui-dropbox:before {
  content: "\e65e";
}
.fui-android:before {
  content: "\e65f";
}
.fui-apple:before {
  content: "\e660";
}
body {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.72222;
  color: #34495e;
  background-color: #ffffff;
}
a {
  color: #16a085;
  text-decoration: none;
  transition: .25s;
}
a:hover,
a:focus {
  color: #1abc9c;
  text-decoration: none;
}
a:focus {
  outline: none;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.72222;
  background-color: #ffffff;
  border: 2px solid #bdc3c7;
  border-radius: 6px;
  transition: all 0.25s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-comment {
  font-size: 15px;
  line-height: 1.2;
  font-style: italic;
  margin: 24px 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
  color: #e7e9ec;
}
h1,
h2,
h3 {
  margin-top: 30px;
  margin-bottom: 15px;
}
h4,
h5,
h6 {
  margin-top: 15px;
  margin-bottom: 15px;
}
h6 {
  font-weight: normal;
}
h1,
.h1 {
  font-size: 61px;
}
h2,
.h2 {
  font-size: 53px;
}
h3,
.h3 {
  font-size: 40px;
}
h4,
.h4 {
  font-size: 29px;
}
h5,
.h5 {
  font-size: 28px;
}
h6,
.h6 {
  font-size: 24px;
}
p {
  font-size: 18px;
  line-height: 1.72222;
  margin: 0 0 15px;
}
.lead {
  margin-bottom: 30px;
  font-size: 28px;
  line-height: 1.46428571;
  font-weight: 300;
}
@media (min-width: 768px) {
  .lead {
    font-size: 30.006px;
  }
}
small,
.small {
  font-size: 83%;
  line-height: 2.067;
}
.text-muted {
  color: #bdc3c7;
}
.text-inverse {
  color: #ffffff;
}
.text-primary {
  color: #1abc9c;
}
a.text-primary:hover {
  color: #148f77;
}
.text-warning {
  color: #f1c40f;
}
a.text-warning:hover {
  color: #c29d0b;
}
.text-danger {
  color: #e74c3c;
}
a.text-danger:hover {
  color: #d62c1a;
}
.text-success {
  color: #2ecc71;
}
a.text-success:hover {
  color: #25a25a;
}
.text-info {
  color: #3498db;
}
a.text-info:hover {
  color: #217dbb;
}
.bg-primary {
  color: #ffffff;
  background-color: #34495e;
}
a.bg-primary:hover {
  background-color: #222f3d;
}
.bg-success {
  background-color: #dff0d8;
}
a.bg-success:hover {
  background-color: #c1e2b3;
}
.bg-info {
  background-color: #d9edf7;
}
a.bg-info:hover {
  background-color: #afd9ee;
}
.bg-warning {
  background-color: #fcf8e3;
}
a.bg-warning:hover {
  background-color: #f7ecb5;
}
.bg-danger {
  background-color: #f2dede;
}
a.bg-danger:hover {
  background-color: #e4b9b9;
}
.page-header {
  padding-bottom: 14px;
  margin: 60px 0 30px;
  border-bottom: 2px solid #e7e9ec;
}
ul,
ol {
  margin-bottom: 15px;
}
dl {
  margin-bottom: 30px;
}
dt,
dd {
  line-height: 1.72222;
}
@media (min-width: 768px) {
  .dl-horizontal dt {
    width: 160px;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}
abbr[title],
abbr[data-original-title] {
  border-bottom: 1px dotted #bdc3c7;
}
blockquote {
  border-left: 3px solid #e7e9ec;
  padding: 0 0 0 16px;
  margin: 0 0 30px;
}
blockquote p {
  font-size: 20px;
  line-height: 1.55;
  font-weight: normal;
  margin-bottom: .4em;
}
blockquote small,
blockquote .small {
  font-size: 18px;
  line-height: 1.72222;
  font-style: italic;
  color: inherit;
}
blockquote small:before,
blockquote .small:before {
  content: "";
}
blockquote.pull-right {
  padding-right: 16px;
  padding-left: 0;
  border-right: 3px solid #e7e9ec;
  border-left: 0;
}
blockquote.pull-right small:after {
  content: "";
}
address {
  margin-bottom: 30px;
  line-height: 1.72222;
}
sub,
sup {
  font-size: 70%;
}
code,
kbd,
pre,
samp {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
}
code {
  padding: 2px 6px;
  font-size: 85%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
kbd {
  padding: 2px 6px;
  font-size: 85%;
  color: #ffffff;
  background-color: #34495e;
  border-radius: 4px;
  box-shadow: none;
}
pre {
  padding: 8px;
  margin: 0 0 15px;
  font-size: 13px;
  line-height: 1.72222;
  color: inherit;
  background-color: #ffffff;
  border: 2px solid #e7e9ec;
  border-radius: 6px;
  white-space: pre;
}
.pre-scrollable {
  max-height: 340px;
}
.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 5px;
  line-height: 1.72222;
  background-color: #ffffff;
  border: 2px solid #bdc3c7;
  border-radius: 6px;
  transition: border 0.25s ease-in-out;
}
.thumbnail > img,
.thumbnail a > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #16a085;
}
.thumbnail .caption {
  padding: 9px;
  color: #34495e;
}
.btn {
  border: none;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.4;
  border-radius: 4px;
  padding: 10px 15px;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
}
.btn:hover,
.btn:focus {
  outline: none;
  color: #ffffff;
}
.btn:active,
.btn.active {
  outline: none;
  box-shadow: none;
}
.btn:focus:active {
  outline: none;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  background-color: #bdc3c7;
  color: rgba(255, 255, 255, 0.75);
  opacity: 0.7;
  filter: alpha(opacity=70);
  cursor: not-allowed;
}
.btn [class^="fui-"] {
  margin: 0 1px;
  position: relative;
  line-height: 1;
  top: 1px;
}
.btn-xs.btn [class^="fui-"] {
  font-size: 11px;
  top: 0;
}
.btn-hg.btn [class^="fui-"] {
  top: 2px;
}
.btn-default {
  color: #ffffff;
  background-color: #bdc3c7;
}
.btn-default:hover,
.btn-default.hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #ffffff;
  background-color: #cacfd2;
  border-color: #cacfd2;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background: #a1a6a9;
  border-color: #a1a6a9;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled.hover,
.btn-default[disabled].hover,
fieldset[disabled] .btn-default.hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}
.btn-default .badge {
  color: #bdc3c7;
  background-color: #ffffff;
}
.btn-primary {
  color: #ffffff;
  background-color: #1abc9c;
}
.btn-primary:hover,
.btn-primary.hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #48c9b0;
  border-color: #48c9b0;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background: #16a085;
  border-color: #16a085;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled.hover,
.btn-primary[disabled].hover,
fieldset[disabled] .btn-primary.hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #bdc3c7;
  border-color: #1abc9c;
}
.btn-primary .badge {
  color: #1abc9c;
  background-color: #ffffff;
}
.btn-info {
  color: #ffffff;
  background-color: #3498db;
}
.btn-info:hover,
.btn-info.hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #5dade2;
  border-color: #5dade2;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background: #2c81ba;
  border-color: #2c81ba;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled.hover,
.btn-info[disabled].hover,
fieldset[disabled] .btn-info.hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #bdc3c7;
  border-color: #3498db;
}
.btn-info .badge {
  color: #3498db;
  background-color: #ffffff;
}
.btn-danger {
  color: #ffffff;
  background-color: #e74c3c;
}
.btn-danger:hover,
.btn-danger.hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #ffffff;
  background-color: #ec7063;
  border-color: #ec7063;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background: #c44133;
  border-color: #c44133;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled.hover,
.btn-danger[disabled].hover,
fieldset[disabled] .btn-danger.hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #bdc3c7;
  border-color: #e74c3c;
}
.btn-danger .badge {
  color: #e74c3c;
  background-color: #ffffff;
}
.btn-success {
  color: #ffffff;
  background-color: #2ecc71;
}
.btn-success:hover,
.btn-success.hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: #58d68d;
  border-color: #58d68d;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background: #27ad60;
  border-color: #27ad60;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled.hover,
.btn-success[disabled].hover,
fieldset[disabled] .btn-success.hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #bdc3c7;
  border-color: #2ecc71;
}
.btn-success .badge {
  color: #2ecc71;
  background-color: #ffffff;
}
.btn-warning {
  color: #ffffff;
  background-color: #f1c40f;
}
.btn-warning:hover,
.btn-warning.hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #f4d313;
  border-color: #f4d313;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background: #cda70d;
  border-color: #cda70d;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled.hover,
.btn-warning[disabled].hover,
fieldset[disabled] .btn-warning.hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #bdc3c7;
  border-color: #f1c40f;
}
.btn-warning .badge {
  color: #f1c40f;
  background-color: #ffffff;
}
.btn-inverse {
  color: #ffffff;
  background-color: #34495e;
}
.btn-inverse:hover,
.btn-inverse.hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  color: #ffffff;
  background-color: #415b76;
  border-color: #415b76;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background: #2c3e50;
  border-color: #2c3e50;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled.hover,
.btn-inverse[disabled].hover,
fieldset[disabled] .btn-inverse.hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
  background-color: #bdc3c7;
  border-color: #34495e;
}
.btn-inverse .badge {
  color: #34495e;
  background-color: #ffffff;
}
.btn-embossed {
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
}
.btn-embossed.active,
.btn-embossed:active {
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
}
.btn-wide {
  min-width: 140px;
  padding-left: 30px;
  padding-right: 30px;
}
.btn-link {
  color: #16a085;
}
.btn-link:hover,
.btn-link:focus {
  color: #1abc9c;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #bdc3c7;
  text-decoration: none;
}
.btn-hg,
.btn-group-hg > .btn {
  padding: 13px 20px;
  font-size: 22px;
  line-height: 1.227;
  border-radius: 6px;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 19px;
  font-size: 17px;
  line-height: 1.471;
  border-radius: 6px;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 9px 13px;
  font-size: 13px;
  line-height: 1.385;
  border-radius: 4px;
}
.btn-xs,
.btn-group-xs > .btn {
  padding: 6px 9px;
  font-size: 12px;
  line-height: 1.083;
  border-radius: 3px;
}
.btn-tip {
  font-weight: 300;
  padding-left: 10px;
  font-size: 92%;
}
.btn-block {
  white-space: normal;
}
[class*="btn-social-"] {
  padding: 10px 15px;
  font-size: 13px;
  line-height: 1.077;
  border-radius: 4px;
}
.btn-social-pinterest {
  color: #ffffff;
  background-color: #cb2028;
}
.btn-social-pinterest:hover,
.btn-social-pinterest:focus {
  background-color: #d54d53;
}
.btn-social-pinterest:active,
.btn-social-pinterest.active {
  background-color: #ad1b22;
}
.btn-social-linkedin {
  color: #ffffff;
  background-color: #0072b5;
}
.btn-social-linkedin:hover,
.btn-social-linkedin:focus {
  background-color: #338ec4;
}
.btn-social-linkedin:active,
.btn-social-linkedin.active {
  background-color: #00619a;
}
.btn-social-stumbleupon {
  color: #ffffff;
  background-color: #ed4a13;
}
.btn-social-stumbleupon:hover,
.btn-social-stumbleupon:focus {
  background-color: #f16e42;
}
.btn-social-stumbleupon:active,
.btn-social-stumbleupon.active {
  background-color: #c93f10;
}
.btn-social-googleplus {
  color: #ffffff;
  background-color: #2d2d2d;
}
.btn-social-googleplus:hover,
.btn-social-googleplus:focus {
  background-color: #575757;
}
.btn-social-googleplus:active,
.btn-social-googleplus.active {
  background-color: #262626;
}
.btn-social-facebook {
  color: #ffffff;
  background-color: #2f4b93;
}
.btn-social-facebook:hover,
.btn-social-facebook:focus {
  background-color: #596fa9;
}
.btn-social-facebook:active,
.btn-social-facebook.active {
  background-color: #28407d;
}
.btn-social-twitter {
  color: #ffffff;
  background-color: #00bdef;
}
.btn-social-twitter:hover,
.btn-social-twitter:focus {
  background-color: #33caf2;
}
.btn-social-twitter:active,
.btn-social-twitter.active {
  background-color: #00a1cb;
}
.btn-group > .btn + .btn {
  margin-left: 0;
}
.btn-group > .btn + .dropdown-toggle {
  border-left: 2px solid rgba(52, 73, 94, 0.15);
  padding: 10px 12px;
}
.btn-group > .btn + .dropdown-toggle .caret {
  margin-left: 3px;
  margin-right: 3px;
}
.btn-group > .btn.btn-gh + .dropdown-toggle .caret {
  margin-left: 7px;
  margin-right: 7px;
}
.btn-group > .btn.btn-sm + .dropdown-toggle .caret {
  margin-left: 0;
  margin-right: 0;
}
.dropdown-toggle .caret {
  margin-left: 8px;
}
.btn-group-xs > .btn + .dropdown-toggle {
  padding: 6px 9px;
}
.btn-group-sm > .btn + .dropdown-toggle {
  padding: 9px 13px;
}
.btn-group-lg > .btn + .dropdown-toggle {
  padding: 10px 19px;
}
.btn-group-hg > .btn + .dropdown-toggle {
  padding: 13px 20px;
}
.btn-xs .caret {
  border-width: 6px 4px 0;
  border-bottom-width: 0;
}
.btn-lg .caret {
  border-width: 8px 6px 0;
  border-bottom-width: 0;
}
.dropup .btn-lg .caret {
  border-width: 0 6px 8px;
}
.dropup .btn-xs .caret {
  border-width: 0 4px 6px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
  font-weight: 400;
}
.btn-group:focus .dropdown-toggle {
  outline: none;
  transition: .25s;
}
.btn-group.open .dropdown-toggle {
  color: rgba(255, 255, 255, 0.75);
  box-shadow: none;
}
.btn-toolbar .btn.active {
  color: #ffffff;
}
.btn-toolbar .btn > [class^="fui-"] {
  font-size: 16px;
  margin: 0 1px;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 30px / 2;
  font-size: 24px;
  line-height: inherit;
  color: inherit;
  border-bottom: none;
}
textarea {
  font-size: 20px;
  line-height: 24px;
  padding: 5px 11px;
}
input[type="search"] {
  -webkit-appearance: none !important;
}
label {
  font-weight: normal;
  font-size: 15px;
  line-height: 2.3;
}
.form-control::-moz-placeholder,
.select2-search input[type="text"]::-moz-placeholder {
  color: #b2bcc5;
  opacity: 1;
}
.form-control:-ms-input-placeholder,
.select2-search input[type="text"]:-ms-input-placeholder {
  color: #b2bcc5;
}
.form-control::-webkit-input-placeholder,
.select2-search input[type="text"]::-webkit-input-placeholder {
  color: #b2bcc5;
}
.form-control,
.select2-search input[type="text"] {
  border: 2px solid #bdc3c7;
  color: #34495e;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.467;
  padding: 8px 12px;
  height: 42px;
  border-radius: 6px;
  box-shadow: none;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
}
.form-group.focus .form-control,
.form-control:focus,
.form-group.focus .select2-search input[type="text"],
.select2-search input[type="text"]:focus {
  border-color: #1abc9c;
  outline: 0;
  box-shadow: none;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control,
.select2-search input[type="text"][disabled],
.select2-search input[type="text"][readonly],
fieldset[disabled] .select2-search input[type="text"] {
  background-color: #f4f6f6;
  border-color: #d5dbdb;
  color: #d5dbdb;
  cursor: default;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.form-control.flat,
.select2-search input[type="text"].flat {
  border-color: transparent;
}
.form-control.flat:hover,
.select2-search input[type="text"].flat:hover {
  border-color: #bdc3c7;
}
.form-control.flat:focus,
.select2-search input[type="text"].flat:focus {
  border-color: #1abc9c;
}
.input-sm,
.form-group-sm .form-control,
.form-group-sm .select2-search input[type="text"],
.select2-search input[type="text"] {
  height: 35px;
  padding: 6px 10px;
  font-size: 13px;
  line-height: 1.462;
  border-radius: 6px;
}
select.input-sm,
select.form-group-sm .form-control,
select.form-group-sm .select2-search input[type="text"],
select.select2-search input[type="text"] {
  height: 35px;
  line-height: 35px;
}
textarea.input-sm,
textarea.form-group-sm .form-control,
select[multiple].input-sm,
select[multiple].form-group-sm .form-control,
textarea.form-group-sm .select2-search input[type="text"],
select[multiple].form-group-sm .select2-search input[type="text"],
textarea.select2-search input[type="text"],
select[multiple].select2-search input[type="text"] {
  height: auto;
}
.input-lg,
.form-group-lg .form-control,
.form-group-lg .select2-search input[type="text"] {
  height: 45px;
  padding: 10px 15px;
  font-size: 17px;
  line-height: 1.235;
  border-radius: 6px;
}
select.input-lg,
select.form-group-lg .form-control,
select.form-group-lg .select2-search input[type="text"] {
  height: 45px;
  line-height: 45px;
}
textarea.input-lg,
textarea.form-group-lg .form-control,
select[multiple].input-lg,
select[multiple].form-group-lg .form-control,
textarea.form-group-lg .select2-search input[type="text"],
select[multiple].form-group-lg .select2-search input[type="text"] {
  height: auto;
}
.input-hg,
.form-group-hg .form-control,
.form-horizontal .form-group-hg .form-control,
.form-group-hg .select2-search input[type="text"],
.form-horizontal .form-group-hg .select2-search input[type="text"] {
  height: 53px;
  padding: 10px 16px;
  font-size: 22px;
  line-height: 1.318;
  border-radius: 6px;
}
select.input-hg,
select.form-group-hg .form-control,
select.form-group-hg .select2-search input[type="text"] {
  height: 53px;
  line-height: 53px;
}
textarea.input-hg,
textarea.form-group-hg .form-control,
select[multiple].input-hg,
select[multiple].form-group-hg .form-control,
textarea.form-group-hg .select2-search input[type="text"],
select[multiple].form-group-hg .select2-search input[type="text"] {
  height: auto;
}
.form-control-feedback {
  position: absolute;
  top: 2px;
  right: 2px;
  margin-top: 1px;
  line-height: 36px;
  font-size: 17px;
  color: #b2bcc5;
  background-color: transparent;
  padding: 0 12px 0 0;
  border-radius: 6px;
  pointer-events: none;
}
.input-hg + .form-control-feedback,
.control-feedback-hg {
  font-size: 20px;
  line-height: 48px;
  padding-right: 16px;
  width: auto;
  height: 48px;
}
.input-lg + .form-control-feedback,
.control-feedback-lg {
  font-size: 18px;
  line-height: 40px;
  width: auto;
  height: 40px;
  padding-right: 15px;
}
.input-sm + .form-control-feedback,
.control-feedback-sm,
.select2-search input[type="text"] + .form-control-feedback {
  line-height: 29px;
  height: 29px;
  width: auto;
  padding-right: 10px;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #2ecc71;
}
.has-success .form-control,
.has-success .select2-search input[type="text"] {
  color: #2ecc71;
  border-color: #2ecc71;
  box-shadow: none;
}
.has-success .form-control::-moz-placeholder,
.has-success .select2-search input[type="text"]::-moz-placeholder {
  color: #2ecc71;
  opacity: 1;
}
.has-success .form-control:-ms-input-placeholder,
.has-success .select2-search input[type="text"]:-ms-input-placeholder {
  color: #2ecc71;
}
.has-success .form-control::-webkit-input-placeholder,
.has-success .select2-search input[type="text"]::-webkit-input-placeholder {
  color: #2ecc71;
}
.has-success .form-control:focus,
.has-success .select2-search input[type="text"]:focus {
  border-color: #2ecc71;
  box-shadow: none;
}
.has-success .input-group-addon {
  color: #2ecc71;
  border-color: #2ecc71;
  background-color: #ffffff;
}
.has-success .form-control-feedback {
  color: #2ecc71;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #f1c40f;
}
.has-warning .form-control,
.has-warning .select2-search input[type="text"] {
  color: #f1c40f;
  border-color: #f1c40f;
  box-shadow: none;
}
.has-warning .form-control::-moz-placeholder,
.has-warning .select2-search input[type="text"]::-moz-placeholder {
  color: #f1c40f;
  opacity: 1;
}
.has-warning .form-control:-ms-input-placeholder,
.has-warning .select2-search input[type="text"]:-ms-input-placeholder {
  color: #f1c40f;
}
.has-warning .form-control::-webkit-input-placeholder,
.has-warning .select2-search input[type="text"]::-webkit-input-placeholder {
  color: #f1c40f;
}
.has-warning .form-control:focus,
.has-warning .select2-search input[type="text"]:focus {
  border-color: #f1c40f;
  box-shadow: none;
}
.has-warning .input-group-addon {
  color: #f1c40f;
  border-color: #f1c40f;
  background-color: #ffffff;
}
.has-warning .form-control-feedback {
  color: #f1c40f;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #e74c3c;
}
.has-error .form-control,
.has-error .select2-search input[type="text"] {
  color: #e74c3c;
  border-color: #e74c3c;
  box-shadow: none;
}
.has-error .form-control::-moz-placeholder,
.has-error .select2-search input[type="text"]::-moz-placeholder {
  color: #e74c3c;
  opacity: 1;
}
.has-error .form-control:-ms-input-placeholder,
.has-error .select2-search input[type="text"]:-ms-input-placeholder {
  color: #e74c3c;
}
.has-error .form-control::-webkit-input-placeholder,
.has-error .select2-search input[type="text"]::-webkit-input-placeholder {
  color: #e74c3c;
}
.has-error .form-control:focus,
.has-error .select2-search input[type="text"]:focus {
  border-color: #e74c3c;
  box-shadow: none;
}
.has-error .input-group-addon {
  color: #e74c3c;
  border-color: #e74c3c;
  background-color: #ffffff;
}
.has-error .form-control-feedback {
  color: #e74c3c;
}
.form-control[disabled] + .form-control-feedback,
.form-control[readonly] + .form-control-feedback,
fieldset[disabled] .form-control + .form-control-feedback,
.form-control.disabled + .form-control-feedback,
.select2-search input[type="text"][disabled] + .form-control-feedback,
.select2-search input[type="text"][readonly] + .form-control-feedback,
fieldset[disabled] .select2-search input[type="text"] + .form-control-feedback,
.select2-search input[type="text"].disabled + .form-control-feedback {
  cursor: not-allowed;
  color: #d5dbdb;
  background-color: transparent;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.help-block {
  font-size: 14px;
  margin-bottom: 5px;
  color: #6b7a88;
}
.form-group {
  position: relative;
  margin-bottom: 20px;
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}
.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}
.form-horizontal .form-control-static {
  padding-top: 6px;
  padding-bottom: 6px;
}
@media (min-width: 768px) {
  .form-horizontal .form-group-hg .control-label {
    font-size: 22px;
    padding-top: 2px;
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    font-size: 17px;
    padding-top: 3px;
    padding-bottom: 2px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
.input-group .form-control,
.input-group .select2-search input[type="text"] {
  position: static;
}
.input-group-hg > .form-control,
.input-group-hg > .input-group-addon,
.input-group-hg > .input-group-btn > .btn,
.input-group-hg > .select2-search input[type="text"] {
  height: 53px;
  padding: 10px 16px;
  font-size: 22px;
  line-height: 1.318;
  border-radius: 6px;
}
select.input-group-hg > .form-control,
select.input-group-hg > .input-group-addon,
select.input-group-hg > .input-group-btn > .btn,
select.input-group-hg > .select2-search input[type="text"] {
  height: 53px;
  line-height: 53px;
}
textarea.input-group-hg > .form-control,
textarea.input-group-hg > .input-group-addon,
textarea.input-group-hg > .input-group-btn > .btn,
select[multiple].input-group-hg > .form-control,
select[multiple].input-group-hg > .input-group-addon,
select[multiple].input-group-hg > .input-group-btn > .btn,
textarea.input-group-hg > .select2-search input[type="text"],
select[multiple].input-group-hg > .select2-search input[type="text"] {
  height: auto;
}
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn,
.input-group-lg > .select2-search input[type="text"] {
  height: 45px;
  padding: 10px 15px;
  font-size: 17px;
  line-height: 1.235;
  border-radius: 6px;
}
select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn,
select.input-group-lg > .select2-search input[type="text"] {
  height: 45px;
  line-height: 45px;
}
textarea.input-group-lg > .form-control,
textarea.input-group-lg > .input-group-addon,
textarea.input-group-lg > .input-group-btn > .btn,
select[multiple].input-group-lg > .form-control,
select[multiple].input-group-lg > .input-group-addon,
select[multiple].input-group-lg > .input-group-btn > .btn,
textarea.input-group-lg > .select2-search input[type="text"],
select[multiple].input-group-lg > .select2-search input[type="text"] {
  height: auto;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn,
.input-group-sm > .select2-search input[type="text"] {
  height: 35px;
  padding: 6px 10px;
  font-size: 13px;
  line-height: 1.462;
  border-radius: 6px;
}
select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn,
select.input-group-sm > .select2-search input[type="text"] {
  height: 35px;
  line-height: 35px;
}
textarea.input-group-sm > .form-control,
textarea.input-group-sm > .input-group-addon,
textarea.input-group-sm > .input-group-btn > .btn,
select[multiple].input-group-sm > .form-control,
select[multiple].input-group-sm > .input-group-addon,
select[multiple].input-group-sm > .input-group-btn > .btn,
textarea.input-group-sm > .select2-search input[type="text"],
select[multiple].input-group-sm > .select2-search input[type="text"] {
  height: auto;
}
.input-group-addon {
  padding: 10px 12px;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  background-color: #bdc3c7;
  border: 2px solid #bdc3c7;
  border-radius: 6px;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
}
.input-group-hg .input-group-addon,
.input-group-lg .input-group-addon,
.input-group-sm .input-group-addon {
  line-height: 1;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group .select2-search input[type="text"]:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group .select2-search input[type="text"]:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.form-group.focus .input-group-addon,
.input-group.focus .input-group-addon {
  background-color: #1abc9c;
  border-color: #1abc9c;
}
.form-group.focus .input-group-btn > .btn-default + .btn-default,
.input-group.focus .input-group-btn > .btn-default + .btn-default {
  border-left-color: #16a085;
}
.form-group.focus .input-group-btn .btn,
.input-group.focus .input-group-btn .btn {
  border-color: #1abc9c;
  background-color: #ffffff;
  color: #1abc9c;
}
.form-group.focus .input-group-btn .btn-default,
.input-group.focus .input-group-btn .btn-default {
  color: #ffffff;
  background-color: #1abc9c;
}
.form-group.focus .input-group-btn .btn-default:hover,
.input-group.focus .input-group-btn .btn-default:hover,
.form-group.focus .input-group-btn .btn-default.hover,
.input-group.focus .input-group-btn .btn-default.hover,
.form-group.focus .input-group-btn .btn-default:focus,
.input-group.focus .input-group-btn .btn-default:focus,
.form-group.focus .input-group-btn .btn-default:active,
.input-group.focus .input-group-btn .btn-default:active,
.form-group.focus .input-group-btn .btn-default.active,
.input-group.focus .input-group-btn .btn-default.active,
.open > .dropdown-toggle.form-group.focus .input-group-btn .btn-default,
.open > .dropdown-toggle.input-group.focus .input-group-btn .btn-default {
  color: #ffffff;
  background-color: #48c9b0;
  border-color: #48c9b0;
}
.form-group.focus .input-group-btn .btn-default:active,
.input-group.focus .input-group-btn .btn-default:active,
.form-group.focus .input-group-btn .btn-default.active,
.input-group.focus .input-group-btn .btn-default.active,
.open > .dropdown-toggle.form-group.focus .input-group-btn .btn-default,
.open > .dropdown-toggle.input-group.focus .input-group-btn .btn-default {
  background: #16a085;
  border-color: #16a085;
}
.form-group.focus .input-group-btn .btn-default.disabled,
.input-group.focus .input-group-btn .btn-default.disabled,
.form-group.focus .input-group-btn .btn-default[disabled],
.input-group.focus .input-group-btn .btn-default[disabled],
fieldset[disabled] .form-group.focus .input-group-btn .btn-default,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default,
.form-group.focus .input-group-btn .btn-default.disabled:hover,
.input-group.focus .input-group-btn .btn-default.disabled:hover,
.form-group.focus .input-group-btn .btn-default[disabled]:hover,
.input-group.focus .input-group-btn .btn-default[disabled]:hover,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:hover,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default:hover,
.form-group.focus .input-group-btn .btn-default.disabled.hover,
.input-group.focus .input-group-btn .btn-default.disabled.hover,
.form-group.focus .input-group-btn .btn-default[disabled].hover,
.input-group.focus .input-group-btn .btn-default[disabled].hover,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default.hover,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default.hover,
.form-group.focus .input-group-btn .btn-default.disabled:focus,
.input-group.focus .input-group-btn .btn-default.disabled:focus,
.form-group.focus .input-group-btn .btn-default[disabled]:focus,
.input-group.focus .input-group-btn .btn-default[disabled]:focus,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:focus,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default:focus,
.form-group.focus .input-group-btn .btn-default.disabled:active,
.input-group.focus .input-group-btn .btn-default.disabled:active,
.form-group.focus .input-group-btn .btn-default[disabled]:active,
.input-group.focus .input-group-btn .btn-default[disabled]:active,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:active,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default:active,
.form-group.focus .input-group-btn .btn-default.disabled.active,
.input-group.focus .input-group-btn .btn-default.disabled.active,
.form-group.focus .input-group-btn .btn-default[disabled].active,
.input-group.focus .input-group-btn .btn-default[disabled].active,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default.active,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default.active {
  background-color: #bdc3c7;
  border-color: #1abc9c;
}
.form-group.focus .input-group-btn .btn-default .badge,
.input-group.focus .input-group-btn .btn-default .badge {
  color: #1abc9c;
  background-color: #ffffff;
}
.input-group-btn .btn {
  background-color: #ffffff;
  border: 2px solid #bdc3c7;
  color: #bdc3c7;
  line-height: 18px;
  height: 42px;
}
.input-group-btn .btn-default {
  color: #ffffff;
  background-color: #bdc3c7;
}
.input-group-btn .btn-default:hover,
.input-group-btn .btn-default.hover,
.input-group-btn .btn-default:focus,
.input-group-btn .btn-default:active,
.input-group-btn .btn-default.active,
.open > .dropdown-toggle.input-group-btn .btn-default {
  color: #ffffff;
  background-color: #cacfd2;
  border-color: #cacfd2;
}
.input-group-btn .btn-default:active,
.input-group-btn .btn-default.active,
.open > .dropdown-toggle.input-group-btn .btn-default {
  background: #a1a6a9;
  border-color: #a1a6a9;
}
.input-group-btn .btn-default.disabled,
.input-group-btn .btn-default[disabled],
fieldset[disabled] .input-group-btn .btn-default,
.input-group-btn .btn-default.disabled:hover,
.input-group-btn .btn-default[disabled]:hover,
fieldset[disabled] .input-group-btn .btn-default:hover,
.input-group-btn .btn-default.disabled.hover,
.input-group-btn .btn-default[disabled].hover,
fieldset[disabled] .input-group-btn .btn-default.hover,
.input-group-btn .btn-default.disabled:focus,
.input-group-btn .btn-default[disabled]:focus,
fieldset[disabled] .input-group-btn .btn-default:focus,
.input-group-btn .btn-default.disabled:active,
.input-group-btn .btn-default[disabled]:active,
fieldset[disabled] .input-group-btn .btn-default:active,
.input-group-btn .btn-default.disabled.active,
.input-group-btn .btn-default[disabled].active,
fieldset[disabled] .input-group-btn .btn-default.active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}
.input-group-btn .btn-default .badge {
  color: #bdc3c7;
  background-color: #ffffff;
}
.input-group-hg .input-group-btn .btn {
  line-height: 31px;
}
.input-group-lg .input-group-btn .btn {
  line-height: 21px;
}
.input-group-sm .input-group-btn .btn {
  line-height: 19px;
}
.input-group-btn:first-child > .btn {
  border-right-width: 0;
  margin-right: -3px;
}
.input-group-btn:last-child > .btn {
  border-left-width: 0;
  margin-left: -3px;
}
.input-group-btn > .btn-default + .btn-default {
  border-left: 2px solid #bdc3c7;
}
.input-group-btn > .btn:first-child + .btn .caret {
  margin-left: 0;
}
.input-group-rounded .input-group-btn + .form-control,
.input-group-rounded .input-group-btn:last-child .btn,
.input-group-rounded .input-group-btn + .select2-search input[type="text"] {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.input-group-hg.input-group-rounded .input-group-btn + .form-control,
.input-group-hg.input-group-rounded .input-group-btn:last-child .btn,
.input-group-hg.input-group-rounded .input-group-btn + .select2-search input[type="text"] {
  border-bottom-right-radius: 27px;
  border-top-right-radius: 27px;
}
.input-group-lg.input-group-rounded .input-group-btn + .form-control,
.input-group-lg.input-group-rounded .input-group-btn:last-child .btn,
.input-group-lg.input-group-rounded .input-group-btn + .select2-search input[type="text"] {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
.input-group-rounded .form-control:first-child,
.input-group-rounded .input-group-btn:first-child .btn,
.input-group-rounded .select2-search input[type="text"]:first-child {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.input-group-hg.input-group-rounded .form-control:first-child,
.input-group-hg.input-group-rounded .input-group-btn:first-child .btn,
.input-group-hg.input-group-rounded .select2-search input[type="text"]:first-child {
  border-bottom-left-radius: 27px;
  border-top-left-radius: 27px;
}
.input-group-lg.input-group-rounded .form-control:first-child,
.input-group-lg.input-group-rounded .input-group-btn:first-child .btn,
.input-group-lg.input-group-rounded .select2-search input[type="text"]:first-child {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
.input-group-rounded .input-group-btn + .form-control,
.input-group-rounded .input-group-btn + .select2-search input[type="text"] {
  padding-left: 0;
}
.checkbox,
.radio {
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  transition: color .25s linear;
  font-size: 14px;
  line-height: 1.5;
}
.checkbox .icons,
.radio .icons {
  color: #bdc3c7;
  display: block;
  height: 20px;
  top: 0;
  left: 0;
  position: absolute;
  width: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  cursor: pointer;
}
.checkbox .icons .icon-checked,
.radio .icons .icon-checked {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox .icon-checked,
.radio .icon-checked,
.checkbox .icon-unchecked,
.radio .icon-unchecked {
  display: inline-table;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  margin: 0;
  opacity: 1;
  filter: none;
  transition: color .25s linear;
}
.checkbox .icon-checked:before,
.radio .icon-checked:before,
.checkbox .icon-unchecked:before,
.radio .icon-unchecked:before {
  font-family: 'Flat-UI-Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.checkbox .icon-checked:before {
  content: "\e60e";
}
.checkbox .icon-unchecked:before {
  content: "\e60d";
}
.radio .icon-checked:before {
  content: "\e60c";
}
.radio .icon-unchecked:before {
  content: "\e60b";
}
.checkbox input[type="checkbox"].custom-checkbox,
.radio input[type="checkbox"].custom-checkbox,
.checkbox input[type="radio"].custom-radio,
.radio input[type="radio"].custom-radio {
  outline: none !important;
  opacity: 0;
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
}
.checkbox input[type="checkbox"].custom-checkbox:hover:not(.nohover):not(:disabled) + .icons .icon-unchecked,
.radio input[type="checkbox"].custom-checkbox:hover:not(.nohover):not(:disabled) + .icons .icon-unchecked,
.checkbox input[type="radio"].custom-radio:hover:not(.nohover):not(:disabled) + .icons .icon-unchecked,
.radio input[type="radio"].custom-radio:hover:not(.nohover):not(:disabled) + .icons .icon-unchecked {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox input[type="checkbox"].custom-checkbox:hover:not(.nohover):not(:disabled) + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:hover:not(.nohover):not(:disabled) + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:hover:not(.nohover):not(:disabled) + .icons .icon-checked,
.radio input[type="radio"].custom-radio:hover:not(.nohover):not(:disabled) + .icons .icon-checked {
  opacity: 1;
  filter: none;
}
.checkbox input[type="checkbox"].custom-checkbox:checked + .icons,
.radio input[type="checkbox"].custom-checkbox:checked + .icons,
.checkbox input[type="radio"].custom-radio:checked + .icons,
.radio input[type="radio"].custom-radio:checked + .icons {
  color: #1abc9c;
}
.checkbox input[type="checkbox"].custom-checkbox:checked + .icons .icon-unchecked,
.radio input[type="checkbox"].custom-checkbox:checked + .icons .icon-unchecked,
.checkbox input[type="radio"].custom-radio:checked + .icons .icon-unchecked,
.radio input[type="radio"].custom-radio:checked + .icons .icon-unchecked {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox input[type="checkbox"].custom-checkbox:checked + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:checked + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:checked + .icons .icon-checked,
.radio input[type="radio"].custom-radio:checked + .icons .icon-checked {
  opacity: 1;
  filter: none;
  color: #1abc9c;
}
.checkbox input[type="checkbox"].custom-checkbox:disabled + .icons,
.radio input[type="checkbox"].custom-checkbox:disabled + .icons,
.checkbox input[type="radio"].custom-radio:disabled + .icons,
.radio input[type="radio"].custom-radio:disabled + .icons {
  cursor: default;
  color: #e6e8ea;
}
.checkbox input[type="checkbox"].custom-checkbox:disabled + .icons .icon-unchecked,
.radio input[type="checkbox"].custom-checkbox:disabled + .icons .icon-unchecked,
.checkbox input[type="radio"].custom-radio:disabled + .icons .icon-unchecked,
.radio input[type="radio"].custom-radio:disabled + .icons .icon-unchecked {
  opacity: 1;
  filter: none;
}
.checkbox input[type="checkbox"].custom-checkbox:disabled + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:disabled + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:disabled + .icons .icon-checked,
.radio input[type="radio"].custom-radio:disabled + .icons .icon-checked {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox input[type="checkbox"].custom-checkbox:disabled:checked + .icons,
.radio input[type="checkbox"].custom-checkbox:disabled:checked + .icons,
.checkbox input[type="radio"].custom-radio:disabled:checked + .icons,
.radio input[type="radio"].custom-radio:disabled:checked + .icons {
  color: #e6e8ea;
}
.checkbox input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icon-unchecked,
.radio input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icon-unchecked,
.checkbox input[type="radio"].custom-radio:disabled:checked + .icons .icon-unchecked,
.radio input[type="radio"].custom-radio:disabled:checked + .icons .icon-unchecked {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:disabled:checked + .icons .icon-checked,
.radio input[type="radio"].custom-radio:disabled:checked + .icons .icon-checked {
  opacity: 1;
  filter: none;
  color: #e6e8ea;
}
.checkbox input[type="checkbox"].custom-checkbox:indeterminate + .icons,
.radio input[type="checkbox"].custom-checkbox:indeterminate + .icons,
.checkbox input[type="radio"].custom-radio:indeterminate + .icons,
.radio input[type="radio"].custom-radio:indeterminate + .icons {
  color: #bdc3c7;
}
.checkbox input[type="checkbox"].custom-checkbox:indeterminate + .icons .icon-unchecked,
.radio input[type="checkbox"].custom-checkbox:indeterminate + .icons .icon-unchecked,
.checkbox input[type="radio"].custom-radio:indeterminate + .icons .icon-unchecked,
.radio input[type="radio"].custom-radio:indeterminate + .icons .icon-unchecked {
  opacity: 1;
  filter: none;
}
.checkbox input[type="checkbox"].custom-checkbox:indeterminate + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:indeterminate + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:indeterminate + .icons .icon-checked,
.radio input[type="radio"].custom-radio:indeterminate + .icons .icon-checked {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox input[type="checkbox"].custom-checkbox:indeterminate + .icons:before,
.radio input[type="checkbox"].custom-checkbox:indeterminate + .icons:before,
.checkbox input[type="radio"].custom-radio:indeterminate + .icons:before,
.radio input[type="radio"].custom-radio:indeterminate + .icons:before {
  content: "\2013";
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
  width: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 22px;
  z-index: 10;
}
.checkbox.primary input[type="checkbox"].custom-checkbox + .icons,
.radio.primary input[type="checkbox"].custom-checkbox + .icons,
.checkbox.primary input[type="radio"].custom-radio + .icons,
.radio.primary input[type="radio"].custom-radio + .icons {
  color: #34495e;
}
.checkbox.primary input[type="checkbox"].custom-checkbox:checked + .icons,
.radio.primary input[type="checkbox"].custom-checkbox:checked + .icons,
.checkbox.primary input[type="radio"].custom-radio:checked + .icons,
.radio.primary input[type="radio"].custom-radio:checked + .icons {
  color: #1abc9c;
}
.checkbox.primary input[type="checkbox"].custom-checkbox:disabled + .icons,
.radio.primary input[type="checkbox"].custom-checkbox:disabled + .icons,
.checkbox.primary input[type="radio"].custom-radio:disabled + .icons,
.radio.primary input[type="radio"].custom-radio:disabled + .icons {
  cursor: default;
  color: #bdc3c7;
}
.checkbox.primary input[type="checkbox"].custom-checkbox:disabled + .icons.checked,
.radio.primary input[type="checkbox"].custom-checkbox:disabled + .icons.checked,
.checkbox.primary input[type="radio"].custom-radio:disabled + .icons.checked,
.radio.primary input[type="radio"].custom-radio:disabled + .icons.checked {
  color: #bdc3c7;
}
.checkbox.primary input[type="checkbox"].custom-checkbox:indeterminate + .icons,
.radio.primary input[type="checkbox"].custom-checkbox:indeterminate + .icons,
.checkbox.primary input[type="radio"].custom-radio:indeterminate + .icons,
.radio.primary input[type="radio"].custom-radio:indeterminate + .icons {
  color: #34495e;
}
.input-group-addon .radio,
.input-group-addon .checkbox {
  margin: -2px 0;
  padding-left: 20px;
}
.input-group-addon .radio .icons,
.input-group-addon .checkbox .icons {
  color: #e6e8ea;
}
.input-group-addon .radio input[type="checkbox"].custom-checkbox:checked + .icons,
.input-group-addon .checkbox input[type="checkbox"].custom-checkbox:checked + .icons,
.input-group-addon .radio input[type="radio"].custom-radio:checked + .icons,
.input-group-addon .checkbox input[type="radio"].custom-radio:checked + .icons {
  color: #ffffff;
}
.input-group-addon .radio input[type="checkbox"].custom-checkbox:checked + .icons .icon-checked,
.input-group-addon .checkbox input[type="checkbox"].custom-checkbox:checked + .icons .icon-checked,
.input-group-addon .radio input[type="radio"].custom-radio:checked + .icons .icon-checked,
.input-group-addon .checkbox input[type="radio"].custom-radio:checked + .icons .icon-checked {
  color: #ffffff;
}
.input-group-addon .radio input[type="checkbox"].custom-checkbox:disabled + .icons,
.input-group-addon .checkbox input[type="checkbox"].custom-checkbox:disabled + .icons,
.input-group-addon .radio input[type="radio"].custom-radio:disabled + .icons,
.input-group-addon .checkbox input[type="radio"].custom-radio:disabled + .icons {
  color: rgba(230, 232, 234, 0.6);
}
.input-group-addon .radio input[type="checkbox"].custom-checkbox:disabled:checked + .icons,
.input-group-addon .checkbox input[type="checkbox"].custom-checkbox:disabled:checked + .icons,
.input-group-addon .radio input[type="radio"].custom-radio:disabled:checked + .icons,
.input-group-addon .checkbox input[type="radio"].custom-radio:disabled:checked + .icons {
  color: rgba(230, 232, 234, 0.6);
}
.input-group-addon .radio input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icon-checked,
.input-group-addon .checkbox input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icon-checked,
.input-group-addon .radio input[type="radio"].custom-radio:disabled:checked + .icons .icon-checked,
.input-group-addon .checkbox input[type="radio"].custom-radio:disabled:checked + .icons .icon-checked {
  color: rgba(230, 232, 234, 0.6);
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: 10px;
}
.form-inline .checkbox,
.form-inline .radio {
  padding-left: 32px;
}
.bootstrap-tagsinput {
  background-color: #ffffff;
  border: 2px solid #ebedef;
  border-radius: 6px;
  margin-bottom: 18px;
  padding: 6px 1px 1px 6px;
  text-align: left;
  font-size: 0;
}
.bootstrap-tagsinput .tag {
  border-radius: 4px;
  background-color: #ebedef;
  color: #7b8996;
  font-size: 13px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  margin: 0 7px 7px 0;
  line-height: 15px;
  height: 27px;
  padding: 6px 21px;
  transition: .25s linear;
}
.bootstrap-tagsinput .tag > span {
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 0;
  text-align: right;
  text-decoration: none;
  top: 0;
  width: 100%;
  bottom: 0;
  padding: 0 10px 0 0;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity .25s linear;
}
.bootstrap-tagsinput .tag > span:after {
  content: "\e609";
  font-family: "Flat-UI-Icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 27px;
}
.bootstrap-tagsinput .tag:hover {
  background-color: #16a085;
  color: #ffffff;
  padding-right: 28px;
  padding-left: 14px;
}
.bootstrap-tagsinput .tag:hover > span {
  opacity: 1;
  filter: none;
}
.bootstrap-tagsinput input[type="text"] {
  font-size: 14px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: auto !important;
  max-width: inherit;
  min-width: 80px;
  vertical-align: top;
  height: 29px;
  color: #34495e;
}
.bootstrap-tagsinput input[type="text"]:first-child {
  height: 23px;
  margin: 3px 0 8px;
}
.tags_clear {
  clear: both;
  width: 100%;
  height: 0;
}
.not_valid {
  background: #fbd8db !important;
  color: #90111a !important;
  margin-left: 5px !important;
}
.tagsinput-primary {
  margin-bottom: 18px;
}
.tagsinput-primary .bootstrap-tagsinput {
  border-color: #1abc9c;
  margin-bottom: 0;
}
.tagsinput-primary .tag {
  background-color: #1abc9c;
  color: #ffffff;
}
.tagsinput-primary .tag:hover {
  background-color: #16a085;
  color: #ffffff;
}
.bootstrap-tagsinput .twitter-typeahead {
  width: auto;
  vertical-align: top;
}
.bootstrap-tagsinput .twitter-typeahead .tt-input {
  min-width: 200px;
}
.bootstrap-tagsinput .twitter-typeahead .tt-dropdown-menu {
  width: auto;
  min-width: 120px;
  margin-top: 11px;
}
.twitter-typeahead {
  width: 100%;
}
.twitter-typeahead .tt-dropdown-menu {
  width: 100%;
  margin-top: 5px;
  border: 2px solid #1abc9c;
  padding: 5px 0;
  background-color: #ffffff;
  border-radius: 6px;
}
.twitter-typeahead .tt-suggestion p {
  padding: 6px 14px;
  font-size: 14px;
  line-height: 1.429;
  margin: 0;
}
.twitter-typeahead .tt-suggestion:first-child p,
.twitter-typeahead .tt-suggestion:last-child p {
  padding: 6px 14px;
}
.twitter-typeahead .tt-suggestion.tt-is-under-cursor,
.twitter-typeahead .tt-suggestion.tt-cursor {
  cursor: pointer;
  color: #fff;
  background-color: #16a085;
}
.progress {
  background: #ebedef;
  border-radius: 32px;
  height: 12px;
  box-shadow: none;
}
.progress-bar {
  background: #1abc9c;
  line-height: 12px;
  box-shadow: none;
}
.progress-bar-success {
  background-color: #2ecc71;
}
.progress-bar-warning {
  background-color: #f1c40f;
}
.progress-bar-danger {
  background-color: #e74c3c;
}
.progress-bar-info {
  background-color: #3498db;
}
.ui-slider {
  background: #ebedef;
  border-radius: 32px;
  height: 12px;
  box-shadow: none;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}
.ui-slider-handle {
  background-color: #16a085;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  position: absolute;
  width: 18px;
  z-index: 2;
  transition: background .25s;
}
.ui-slider-handle:hover,
.ui-slider-handle:focus {
  background-color: #48c9b0;
  outline: none;
}
.ui-slider-handle:active {
  background-color: #16a085;
}
.ui-slider-range {
  background-color: #1abc9c;
  display: block;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.ui-slider-segment {
  background-color: #d9dbdd;
  border-radius: 50%;
  height: 6px;
  width: 6px;
}
.ui-slider-value {
  float: right;
  font-size: 13px;
  margin-top: 12px;
}
.ui-slider-value.first {
  clear: left;
  float: left;
}
.ui-slider-horizontal .ui-slider-handle {
  margin-left: -9px;
  top: -3px;
}
.ui-slider-horizontal .ui-slider-handle[style*="100"] {
  margin-left: -15px;
}
.ui-slider-horizontal .ui-slider-range {
  border-radius: 30px 0 0 30px;
}
.ui-slider-horizontal .ui-slider-segment {
  float: left;
  margin: 3px -6px 0 0;
}
.ui-slider-vertical {
  width: 12px;
}
.ui-slider-vertical .ui-slider-handle {
  margin-left: -3px;
  margin-bottom: -11px;
  top: auto;
}
.ui-slider-vertical .ui-slider-range {
  width: 100%;
  bottom: 0;
  border-radius: 0 0 30px 30px;
}
.ui-slider-vertical .ui-slider-segment {
  position: absolute;
  right: 3px;
}
.pager {
  background-color: #34495e;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
}
.pager li:first-child > a,
.pager li:first-child > span {
  border-left: none;
  border-radius: 6px 0 0 6px;
}
.pager li > a,
.pager li > span {
  background: none;
  border: none;
  border-left: 2px solid #2c3e50;
  color: #ffffff;
  padding: 9px 15px 10px;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 0 6px 6px 0;
  line-height: 1.313;
}
.pager li > a:hover,
.pager li > span:hover,
.pager li > a:focus,
.pager li > span:focus {
  background-color: #2c3e50;
}
.pager li > a:active,
.pager li > span:active {
  background-color: #2c3e50;
}
.pager li > a [class*="fui-"] + span,
.pager li > span [class*="fui-"] + span {
  margin-left: 8px;
}
.pager li > a span + [class*="fui-"],
.pager li > span span + [class*="fui-"] {
  margin-left: 8px;
}
.pagination {
  position: relative;
  display: block;
  background: #d6dbdf;
  color: #ffffff;
  padding: 0;
  display: inline-block;
  border-radius: 6px;
  word-spacing: -0.5px;
}
@media (min-width: 768px) {
  .pagination {
    display: inline-block;
  }
}
@media (max-width: 767px) {
  .pagination {
    height: 41px;
    padding: 0 55px 0 52px;
    overflow: auto;
    white-space: nowrap;
    border-radius: 6px;
  }
}
.pagination li {
  display: inline-block;
  margin-right: -2px;
  vertical-align: middle;
  word-spacing: normal;
}
.pagination li a {
  position: static;
}
.pagination li.active > a,
.pagination li.active > span {
  background-color: #1abc9c;
  color: #ffffff;
  border-color: #dfe2e5;
}
.pagination li.active > a,
.pagination li.active > span,
.pagination li.active > a:hover,
.pagination li.active > span:hover,
.pagination li.active > a:focus,
.pagination li.active > span:focus {
  background-color: #1abc9c;
  color: #ffffff;
  border-color: #dfe2e5;
}
.pagination li.active.previous > a,
.pagination li.active.next > a,
.pagination li.active.previous > span,
.pagination li.active.next > span {
  margin: 0;
}
.pagination li.active.previous > a,
.pagination li.active.next > a,
.pagination li.active.previous > span,
.pagination li.active.next > span,
.pagination li.active.previous > a:hover,
.pagination li.active.next > a:hover,
.pagination li.active.previous > span:hover,
.pagination li.active.next > span:hover,
.pagination li.active.previous > a:focus,
.pagination li.active.next > a:focus,
.pagination li.active.previous > span:focus,
.pagination li.active.next > span:focus {
  background-color: #1abc9c;
  color: #ffffff;
}
.pagination li:first-child > a,
.pagination li:first-child > span {
  border-radius: 6px 0 0 6px;
  border-left: none;
}
.pagination li:first-child.previous + li > a,
.pagination li:first-child.previous + li > span {
  border-left-width: 0;
}
.pagination li:last-child {
  margin-right: 0;
}
.pagination li:last-child > a,
.pagination li:last-child > span,
.pagination li:last-child > a:hover,
.pagination li:last-child > span:hover,
.pagination li:last-child > a:focus,
.pagination li:last-child > span:focus {
  border-radius: 0 6px 6px 0;
}
.pagination li.previous > a,
.pagination li.next > a,
.pagination li.previous > span,
.pagination li.next > span {
  border-right: 2px solid #e4e7ea;
  font-size: 16px;
  min-width: auto;
  padding: 12px 17px;
  background-color: transparent;
}
.pagination li.next > a,
.pagination li.next > span {
  border-right: none;
}
.pagination li.disabled > a,
.pagination li.disabled > span {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.3);
  border-right-color: #dfe2e5;
  cursor: not-allowed;
}
.pagination li.disabled > a:hover,
.pagination li.disabled > span:hover,
.pagination li.disabled > a:focus,
.pagination li.disabled > span:focus,
.pagination li.disabled > a:active,
.pagination li.disabled > span:active {
  background-color: rgba(255, 255, 255, 0.4);
  color: #ffffff;
}
@media (max-width: 767px) {
  .pagination li.next,
  .pagination li.previous {
    background-color: #d6dbdf;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    border-radius: 0 6px 6px 0;
  }
  .pagination li.previous {
    left: 0;
    right: auto;
    border-radius: 6px 0 0 6px;
  }
}
.pagination li > a,
.pagination li > span {
  display: inline-block;
  background: transparent;
  border: none;
  border-left: 2px solid #e4e7ea;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  min-height: 41px;
  min-width: 41px;
  outline: none;
  padding: 12px 10px;
  text-align: center;
  transition: 0.25s ease-out;
}
.pagination li > a:hover,
.pagination li > span:hover,
.pagination li > a:focus,
.pagination li > span:focus {
  background-color: #1abc9c;
  color: #ffffff;
}
.pagination li > a:active,
.pagination li > span:active {
  background-color: #1abc9c;
  color: #ffffff;
}
.pagination > .btn.previous,
.pagination > .btn.next {
  margin-right: 8px;
  font-size: 14px;
  line-height: 1.429;
  padding-left: 23px;
  padding-right: 23px;
}
.pagination > .btn.previous [class*="fui-"],
.pagination > .btn.next [class*="fui-"] {
  font-size: 16px;
  margin-left: -2px;
  margin-top: -2px;
}
.pagination > .btn.next {
  margin-left: 8px;
  margin-right: 0;
}
.pagination > .btn.next [class*="fui-"] {
  margin-right: -2px;
  margin-left: 4px;
}
@media (max-width: 767px) {
  .pagination > .btn {
    display: block;
    margin: 0;
    width: 50%;
  }
  .pagination > .btn:first-child {
    border-bottom: 2px solid #dfe2e5;
    border-radius: 6px 0 0;
  }
  .pagination > .btn:first-child.btn-primary {
    border-bottom-color: #48c9b0;
  }
  .pagination > .btn:first-child.btn-danger {
    border-bottom-color: #ec7063;
  }
  .pagination > .btn:first-child.btn-warning {
    border-bottom-color: #f4d03f;
  }
  .pagination > .btn:first-child.btn-success {
    border-bottom-color: #58d68d;
  }
  .pagination > .btn:first-child.btn-info {
    border-bottom-color: #5dade2;
  }
  .pagination > .btn:first-child.btn-inverse {
    border-bottom-color: #5d6d7e;
  }
  .pagination > .btn:first-child > [class*="fui"] {
    margin-left: -20px;
  }
  .pagination > .btn + ul {
    padding: 0;
    text-align: center;
    border-radius: 0 0 6px 6px;
  }
  .pagination > .btn + ul + .btn {
    border-bottom: 2px solid #dfe2e5;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 6px 0 0;
  }
  .pagination > .btn + ul + .btn.btn-primary {
    border-bottom-color: #48c9b0;
  }
  .pagination > .btn + ul + .btn.btn-danger {
    border-bottom-color: #ec7063;
  }
  .pagination > .btn + ul + .btn.btn-warning {
    border-bottom-color: #f4d03f;
  }
  .pagination > .btn + ul + .btn.btn-success {
    border-bottom-color: #58d68d;
  }
  .pagination > .btn + ul + .btn.btn-info {
    border-bottom-color: #5dade2;
  }
  .pagination > .btn + ul + .btn.btn-inverse {
    border-bottom-color: #5d6d7e;
  }
  .pagination > .btn + ul + .btn > [class*="fui"] {
    margin-right: -20px;
  }
  .pagination ul {
    display: block;
  }
  .pagination ul > li > a {
    border-radius: 0;
  }
}
.pagination-danger {
  background-color: #e74c3c;
}
.pagination-danger li.previous > a {
  border-right-color: #ef897e;
}
.pagination-danger li > a,
.pagination-danger li > span {
  border-left-color: #ef897e;
}
.pagination-danger li > a:hover,
.pagination-danger li > span:hover,
.pagination-danger li > a:focus,
.pagination-danger li > span:focus {
  border-left-color: #ef897e;
  background-color: #ec7063;
}
.pagination-danger li > a:active,
.pagination-danger li > span:active {
  background-color: #c44133;
}
.pagination-danger li.active > a,
.pagination-danger li.active > span {
  border-left-color: #ef897e;
  background-color: #c44133;
}
.pagination-danger li.active > a:hover,
.pagination-danger li.active > span:hover,
.pagination-danger li.active > a:focus,
.pagination-danger li.active > span:focus {
  border-left-color: #ef897e;
  background-color: #ec7063;
}
.pagination-success {
  background-color: #2ecc71;
}
.pagination-success li.previous > a {
  border-right-color: #75dda1;
}
.pagination-success li > a,
.pagination-success li > span {
  border-left-color: #75dda1;
}
.pagination-success li > a:hover,
.pagination-success li > span:hover,
.pagination-success li > a:focus,
.pagination-success li > span:focus {
  border-left-color: #75dda1;
  background-color: #58d68d;
}
.pagination-success li > a:active,
.pagination-success li > span:active {
  background-color: #27ad60;
}
.pagination-success li.active > a,
.pagination-success li.active > span {
  border-left-color: #75dda1;
  background-color: #27ad60;
}
.pagination-success li.active > a:hover,
.pagination-success li.active > span:hover,
.pagination-success li.active > a:focus,
.pagination-success li.active > span:focus {
  border-left-color: #75dda1;
  background-color: #58d68d;
}
.pagination-warning {
  background-color: #f1c40f;
}
.pagination-warning li.previous > a {
  border-right-color: #f6d861;
}
.pagination-warning li > a,
.pagination-warning li > span {
  border-left-color: #f6d861;
}
.pagination-warning li > a:hover,
.pagination-warning li > span:hover,
.pagination-warning li > a:focus,
.pagination-warning li > span:focus {
  border-left-color: #f6d861;
  background-color: #f4d313;
}
.pagination-warning li > a:active,
.pagination-warning li > span:active {
  background-color: #cda70d;
}
.pagination-warning li.active > a,
.pagination-warning li.active > span {
  border-left-color: #f6d861;
  background-color: #cda70d;
}
.pagination-warning li.active > a:hover,
.pagination-warning li.active > span:hover,
.pagination-warning li.active > a:focus,
.pagination-warning li.active > span:focus {
  border-left-color: #f6d861;
  background-color: #f4d313;
}
.pagination-info {
  background-color: #3498db;
}
.pagination-info li.previous > a {
  border-right-color: #79bbe7;
}
.pagination-info li > a,
.pagination-info li > span {
  border-left-color: #79bbe7;
}
.pagination-info li > a:hover,
.pagination-info li > span:hover,
.pagination-info li > a:focus,
.pagination-info li > span:focus {
  border-left-color: #79bbe7;
  background-color: #5dade2;
}
.pagination-info li > a:active,
.pagination-info li > span:active {
  background-color: #2c81ba;
}
.pagination-info li.active > a,
.pagination-info li.active > span {
  border-left-color: #79bbe7;
  background-color: #2c81ba;
}
.pagination-info li.active > a:hover,
.pagination-info li.active > span:hover,
.pagination-info li.active > a:focus,
.pagination-info li.active > span:focus {
  border-left-color: #79bbe7;
  background-color: #5dade2;
}
.pagination-inverse {
  background-color: #34495e;
}
.pagination-inverse li.previous > a {
  border-right-color: #798795;
}
.pagination-inverse li > a,
.pagination-inverse li > span {
  border-left-color: #798795;
}
.pagination-inverse li > a:hover,
.pagination-inverse li > span:hover,
.pagination-inverse li > a:focus,
.pagination-inverse li > span:focus {
  border-left-color: #798795;
  background-color: #415b76;
}
.pagination-inverse li > a:active,
.pagination-inverse li > span:active {
  background-color: #2c3e50;
}
.pagination-inverse li.active > a,
.pagination-inverse li.active > span {
  border-left-color: #798795;
  background-color: #2c3e50;
}
.pagination-inverse li.active > a:hover,
.pagination-inverse li.active > span:hover,
.pagination-inverse li.active > a:focus,
.pagination-inverse li.active > span:focus {
  border-left-color: #798795;
  background-color: #415b76;
}
.pagination-minimal > li:first-child {
  border-radius: 6px 0 0 6px;
}
.pagination-minimal > li:first-child.previous + li > a,
.pagination-minimal > li:first-child.previous + li > span {
  border-left-width: 5px;
}
.pagination-minimal > li:last-child {
  border-radius: 0 6px 6px 0;
}
.pagination-minimal > li.previous > a,
.pagination-minimal > li.next > a,
.pagination-minimal > li.previous > span,
.pagination-minimal > li.next > span {
  background: transparent;
  border: none;
  border-right: 2px solid #e4e7ea;
  margin: 0 9px 0 0;
  padding: 12px 17px;
  border-radius: 6px 0 0 6px;
}
.pagination-minimal > li.previous > a,
.pagination-minimal > li.next > a,
.pagination-minimal > li.previous > span,
.pagination-minimal > li.next > span,
.pagination-minimal > li.previous > a:hover,
.pagination-minimal > li.next > a:hover,
.pagination-minimal > li.previous > span:hover,
.pagination-minimal > li.next > span:hover,
.pagination-minimal > li.previous > a:focus,
.pagination-minimal > li.next > a:focus,
.pagination-minimal > li.previous > span:focus,
.pagination-minimal > li.next > span:focus {
  border-color: #e4e7ea !important;
}
@media (max-width: 767px) {
  .pagination-minimal > li.previous > a,
  .pagination-minimal > li.next > a,
  .pagination-minimal > li.previous > span,
  .pagination-minimal > li.next > span {
    margin-right: 0;
  }
}
.pagination-minimal > li.next {
  margin-left: 9px;
}
.pagination-minimal > li.next > a,
.pagination-minimal > li.next > span {
  border-left: 2px solid #e4e7ea;
  border-right: none;
  margin: 0;
  border-radius: 0 6px 6px 0;
}
.pagination-minimal > li.active > a,
.pagination-minimal > li.active > span {
  background-color: #ffffff;
  border-color: #ffffff;
  border-width: 2px !important;
  color: #d6dbdf;
  margin: 10px 5px 9px;
}
.pagination-minimal > li.active > a:hover,
.pagination-minimal > li.active > span:hover,
.pagination-minimal > li.active > a:focus,
.pagination-minimal > li.active > span:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #d6dbdf;
}
.pagination-minimal > li.active.previous,
.pagination-minimal > li.active.next {
  border-color: #e4e7ea;
}
.pagination-minimal > li.active.previous {
  margin-right: 6px;
}
.pagination-minimal > li > a,
.pagination-minimal > li > span {
  background: #ffffff;
  border: 5px solid #d6dbdf;
  color: #ffffff;
  line-height: 16px;
  margin: 7px 2px 6px;
  min-width: 0;
  min-height: 16px;
  padding: 0 4px;
  border-radius: 50px;
  background-clip: padding-box;
  transition: background 0.2s ease-out, border-color 0s ease-out, color 0.2s ease-out;
}
.pagination-minimal > li > a:hover,
.pagination-minimal > li > span:hover,
.pagination-minimal > li > a:focus,
.pagination-minimal > li > span:focus {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #ffffff;
  transition: background 0.2s ease-out, border-color 0.2s ease-out, color 0.2s ease-out;
}
.pagination-minimal > li > a:active,
.pagination-minimal > li > span:active {
  background-color: #16a085;
  border-color: #16a085;
}
.pagination-plain {
  font-size: 16px;
  font-weight: 700;
  list-style-type: none;
  margin: 0 0 20px;
  padding: 0;
  height: 57px;
}
.pagination-plain > li {
  display: inline;
}
.pagination-plain > li.previous {
  padding-right: 23px;
}
.pagination-plain > li.next {
  padding-left: 20px;
}
.pagination-plain > li.active > a {
  color: #d3d7da;
}
.pagination-plain > li > a {
  padding: 0 5px;
}
@media (max-width: 480px) {
  .pagination-plain {
    overflow: hidden;
    text-align: center;
  }
  .pagination-plain > li.previous {
    display: block;
    margin-bottom: 10px;
    text-align: left;
    width: 50%;
  }
  .pagination-plain > li.next {
    float: right;
    margin-top: -64px;
    text-align: right;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .pagination-plain {
    height: auto;
  }
}
.pagination-dropdown ul {
  min-width: 67px;
  width: auto;
  left: 50%;
  margin-left: -34px;
}
.pagination-dropdown ul li {
  display: block;
  margin-right: 0;
}
.pagination-dropdown ul li:first-child > a,
.pagination-dropdown ul li:first-child > span {
  border-radius: 6px 6px 0 0;
}
.pagination-dropdown ul li:last-child > a,
.pagination-dropdown ul li:last-child > span {
  border-radius: 0 0 6px 6px !important;
}
.pagination-dropdown ul li > a,
.pagination-dropdown ul li > span {
  border-left: none;
  display: block;
  float: none;
  padding: 8px 10px 7px;
  text-align: center;
  min-height: 0;
}
.pagination-dropdown.dropup {
  position: relative;
}
.tooltip {
  font-size: 14px;
  line-height: 1.286;
  z-index: 1070;
}
.tooltip.in {
  opacity: 1;
  filter: alpha(opacity=100);
}
.tooltip.top {
  margin-top: -5px;
  padding: 9px 0;
}
.tooltip.right {
  margin-left: 5px;
  padding: 0 9px;
}
.tooltip.bottom {
  margin-top: 5px;
  padding: 9px 0;
}
.tooltip.left {
  margin-left: -5px;
  padding: 0 9px;
}
.tooltip-inner {
  max-width: 183px;
  line-height: 1.286;
  padding: 12px 12px;
  color: #ffffff;
  background-color: #34495e;
  border-radius: 6px;
}
.tooltip.top .tooltip-arrow {
  margin-left: -9px;
  border-width: 9px 9px 0;
  border-top-color: #34495e;
}
.tooltip.right .tooltip-arrow {
  margin-top: -9px;
  border-width: 9px 9px 9px 0;
  border-right-color: #34495e;
}
.tooltip.left .tooltip-arrow {
  margin-top: -9px;
  border-width: 9px 0 9px 9px;
  border-left-color: #34495e;
}
.tooltip.bottom .tooltip-arrow {
  margin-left: -9px;
  border-width: 0 9px 9px;
  border-bottom-color: #34495e;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 8px solid;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  transition: border-color 0.25s, color 0.25s;
}
.dropdown-menu,
.select2-drop {
  z-index: 1000;
  background-color: #f3f4f5;
  min-width: 220px;
  border: none;
  margin-top: 9px;
  padding: 0;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: none;
}
.dropdown-menu .divider {
  height: 2px;
  margin: 3px 0;
  overflow: hidden;
  background-color: rgba(202, 206, 209, 0.5);
}
.dropdown-menu > li > a {
  padding: 8px 16px;
  line-height: 1.429;
  color: #606d7a;
}
.dropdown-menu > li:first-child > a:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.dropdown-menu > li:last-child > a:first-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dropdown-menu.typeahead {
  display: none;
  width: auto;
  margin-top: 5px;
  border: 2px solid #1abc9c;
  padding: 5px 0;
  background-color: #ffffff;
  border-radius: 6px;
}
.dropdown-menu.typeahead li a {
  padding: 6px 14px;
}
.dropdown-menu.typeahead li:first-child a,
.dropdown-menu.typeahead li:last-child a {
  padding: 6px 14px;
  border-radius: 0;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #55606c;
  background-color: rgba(202, 206, 209, 0.5);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #ffffff;
  background-color: #1abc9c;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #bdc3c7;
  background-color: transparent;
  cursor: not-allowed;
}
.dropdown-menu-right {
  left: auto;
  right: 0;
}
.dropdown-menu-left {
  left: 0;
  right: auto;
}
.dropdown-header {
  padding: 8px 16px;
  line-height: 1.538;
  font-size: 13px;
  text-transform: uppercase;
  color: rgba(52, 73, 94, 0.6);
}
.dropdown-header:first-child {
  margin-top: 3px;
}
.dropdown-backdrop {
  z-index: 990;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-bottom: 8px solid;
  margin-bottom: .25em;
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-top: 0;
  margin-bottom: 9px;
}
.dropdown-menu-inverse {
  background-color: #34495e;
}
.dropdown-menu-inverse .divider {
  height: 2px;
  margin: 3px 0;
  overflow: hidden;
  background-color: rgba(43, 60, 78, 0.5);
}
.dropdown-menu-inverse > li > a {
  color: rgba(255, 255, 255, 0.85);
}
.dropdown-menu-inverse > li > a:hover,
.dropdown-menu-inverse > li > a:focus {
  color: rgba(255, 255, 255, 0.85);
  background-color: rgba(43, 60, 78, 0.5);
}
.dropdown-menu-inverse > .active > a,
.dropdown-menu-inverse > .active > a:hover,
.dropdown-menu-inverse > .active > a:focus {
  color: rgba(255, 255, 255, 0.85);
  background-color: #1abc9c;
}
.dropdown-menu-inverse > .disabled > a,
.dropdown-menu-inverse > .disabled > a:hover,
.dropdown-menu-inverse > .disabled > a:focus {
  color: rgba(255, 255, 255, 0.5);
}
.dropdown-menu-inverse > .disabled > a:hover,
.dropdown-menu-inverse > .disabled > a:focus {
  background-color: transparent;
}
.dropdown-menu-inverse .dropdown-header {
  color: rgba(255, 255, 255, 0.4);
}
@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}
.select {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 220px;
  width: auto;
}
.form-group .select {
  width: 100%;
}
.form-group .select > .select2-choice {
  width: 100%;
}
.select.form-control,
.select.select2-search input[type="text"] {
  border: none;
  padding: 0;
  height: auto;
}
.select2-choice {
  width: 100%;
  display: inline-block;
  position: relative;
  border: none;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.4;
  border-radius: 4px;
  padding: 10px 39px 10px 15px;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
}
.select2-choice:hover,
.select2-choice:focus {
  outline: none;
}
.select2-choice:active {
  outline: none;
  box-shadow: none;
}
.select2-container-disabled .select2-choice {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.select2-chosen {
  overflow: hidden;
  text-align: left;
}
.select2-arrow {
  display: inline-block;
  border-width: 8px 6px;
  border-color: #34495e transparent;
  border-style: solid;
  border-bottom-style: none;
  position: absolute;
  right: 16px;
  top: 42%;
  transform: scale(1.001);
}
.select2-arrow b {
  display: none;
}
.btn-lg .select2-arrow {
  border-top-width: 8px;
  border-right-width: 6px;
  border-left-width: 6px;
}
.select-default .select2-choice {
  color: #ffffff;
  background-color: #bdc3c7;
}
.select-default .select2-choice:hover,
.select-default .select2-choice.hover,
.select-default .select2-choice:focus,
.select-default .select2-choice:active {
  color: #ffffff;
  background-color: #cacfd2;
  border-color: #cacfd2;
}
.select-default .select2-choice:active {
  background: #a1a6a9;
  border-color: #a1a6a9;
}
.select2-container-disabled.select-default .select2-choice,
.select2-container-disabled.select-default .select2-choice:hover,
.select2-container-disabled.select-default .select2-choice:focus,
.select2-container-disabled.select-default .select2-choice:active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}
.select-default .select2-choice .select2-arrow {
  border-top-color: #ffffff;
}
.select-primary .select2-choice {
  color: #ffffff;
  background-color: #1abc9c;
}
.select-primary .select2-choice:hover,
.select-primary .select2-choice.hover,
.select-primary .select2-choice:focus,
.select-primary .select2-choice:active {
  color: #ffffff;
  background-color: #48c9b0;
  border-color: #48c9b0;
}
.select-primary .select2-choice:active {
  background: #16a085;
  border-color: #16a085;
}
.select2-container-disabled.select-primary .select2-choice,
.select2-container-disabled.select-primary .select2-choice:hover,
.select2-container-disabled.select-primary .select2-choice:focus,
.select2-container-disabled.select-primary .select2-choice:active {
  background-color: #bdc3c7;
  border-color: #1abc9c;
}
.select-primary .select2-choice .select2-arrow {
  border-top-color: #ffffff;
}
.select-info .select2-choice {
  color: #ffffff;
  background-color: #3498db;
}
.select-info .select2-choice:hover,
.select-info .select2-choice.hover,
.select-info .select2-choice:focus,
.select-info .select2-choice:active {
  color: #ffffff;
  background-color: #5dade2;
  border-color: #5dade2;
}
.select-info .select2-choice:active {
  background: #2c81ba;
  border-color: #2c81ba;
}
.select2-container-disabled.select-info .select2-choice,
.select2-container-disabled.select-info .select2-choice:hover,
.select2-container-disabled.select-info .select2-choice:focus,
.select2-container-disabled.select-info .select2-choice:active {
  background-color: #bdc3c7;
  border-color: #3498db;
}
.select-info .select2-choice .select2-arrow {
  border-top-color: #ffffff;
}
.select-danger .select2-choice {
  color: #ffffff;
  background-color: #e74c3c;
}
.select-danger .select2-choice:hover,
.select-danger .select2-choice.hover,
.select-danger .select2-choice:focus,
.select-danger .select2-choice:active {
  color: #ffffff;
  background-color: #ec7063;
  border-color: #ec7063;
}
.select-danger .select2-choice:active {
  background: #c44133;
  border-color: #c44133;
}
.select2-container-disabled.select-danger .select2-choice,
.select2-container-disabled.select-danger .select2-choice:hover,
.select2-container-disabled.select-danger .select2-choice:focus,
.select2-container-disabled.select-danger .select2-choice:active {
  background-color: #bdc3c7;
  border-color: #e74c3c;
}
.select-danger .select2-choice .select2-arrow {
  border-top-color: #ffffff;
}
.select-success .select2-choice {
  color: #ffffff;
  background-color: #2ecc71;
}
.select-success .select2-choice:hover,
.select-success .select2-choice.hover,
.select-success .select2-choice:focus,
.select-success .select2-choice:active {
  color: #ffffff;
  background-color: #58d68d;
  border-color: #58d68d;
}
.select-success .select2-choice:active {
  background: #27ad60;
  border-color: #27ad60;
}
.select2-container-disabled.select-success .select2-choice,
.select2-container-disabled.select-success .select2-choice:hover,
.select2-container-disabled.select-success .select2-choice:focus,
.select2-container-disabled.select-success .select2-choice:active {
  background-color: #bdc3c7;
  border-color: #2ecc71;
}
.select-success .select2-choice .select2-arrow {
  border-top-color: #ffffff;
}
.select-warning .select2-choice {
  color: #ffffff;
  background-color: #f1c40f;
}
.select-warning .select2-choice:hover,
.select-warning .select2-choice.hover,
.select-warning .select2-choice:focus,
.select-warning .select2-choice:active {
  color: #ffffff;
  background-color: #f4d313;
  border-color: #f4d313;
}
.select-warning .select2-choice:active {
  background: #cda70d;
  border-color: #cda70d;
}
.select2-container-disabled.select-warning .select2-choice,
.select2-container-disabled.select-warning .select2-choice:hover,
.select2-container-disabled.select-warning .select2-choice:focus,
.select2-container-disabled.select-warning .select2-choice:active {
  background-color: #bdc3c7;
  border-color: #f1c40f;
}
.select-warning .select2-choice .select2-arrow {
  border-top-color: #ffffff;
}
.select-inverse .select2-choice {
  color: #ffffff;
  background-color: #34495e;
}
.select-inverse .select2-choice:hover,
.select-inverse .select2-choice.hover,
.select-inverse .select2-choice:focus,
.select-inverse .select2-choice:active {
  color: #ffffff;
  background-color: #415b76;
  border-color: #415b76;
}
.select-inverse .select2-choice:active {
  background: #2c3e50;
  border-color: #2c3e50;
}
.select2-container-disabled.select-inverse .select2-choice,
.select2-container-disabled.select-inverse .select2-choice:hover,
.select2-container-disabled.select-inverse .select2-choice:focus,
.select2-container-disabled.select-inverse .select2-choice:active {
  background-color: #bdc3c7;
  border-color: #34495e;
}
.select-inverse .select2-choice .select2-arrow {
  border-top-color: #ffffff;
}
.select2-container.select-hg > .select2-choice {
  padding: 13px 20px;
  font-size: 22px;
  line-height: 1.227;
  border-radius: 6px;
  padding-right: 49px;
  min-height: 53px;
}
.select2-container.select-hg > .select2-choice .filter-option {
  left: 20px;
  right: 40px;
  top: 13px;
}
.select2-container.select-hg > .select2-choice .select2-arrow {
  right: 20px;
}
.select2-container.select-hg > .select2-choice > [class^="fui-"] {
  top: 2px;
}
.select2-container.select-lg > .select2-choice {
  padding: 10px 19px;
  font-size: 17px;
  line-height: 1.471;
  border-radius: 6px;
  padding-right: 47px;
  min-height: 45px;
}
.select2-container.select-lg > .select2-choice .filter-option {
  left: 18px;
  right: 38px;
}
.select2-container.select-sm > .select2-choice {
  padding: 9px 13px;
  font-size: 13px;
  line-height: 1.385;
  border-radius: 4px;
  padding-right: 35px;
  min-height: 36px;
}
.select2-container.select-sm > .select2-choice .filter-option {
  left: 13px;
  right: 33px;
}
.select2-container.select-sm > .select2-choice .select2-arrow {
  right: 13px;
}
.multiselect {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 220px;
  background-color: #ffffff;
  border-radius: 6px;
  text-align: left;
  font-size: 0;
  width: auto;
  max-width: none;
}
.form-group .multiselect {
  width: 100%;
}
.form-group .multiselect > .select2-choice {
  width: 100%;
}
.multiselect.form-control,
.multiselect.select2-search input[type="text"] {
  height: auto;
  padding: 6px 1px 1px 6px;
  border: 2px solid #ebedef;
}
.select2-choices {
  margin: 0;
  padding: 0;
  position: relative;
  cursor: text;
  overflow: hidden;
  min-height: 26px;
}
.select2-choices li {
  float: left;
  list-style: none;
}
.select2-search-choice {
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  margin: 0 5px 4px 0;
  line-height: 15px;
  height: 27px;
  padding: 6px 21px;
  transition: .25s linear;
}
.select2-search-choice:hover {
  padding-right: 28px;
  padding-left: 14px;
  color: #ffffff;
}
.select2-search-choice:hover .select2-search-choice-close {
  opacity: 1;
  filter: none;
  color: inherit;
}
.select2-search-choice .select2-search-choice-close {
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 0;
  text-align: right;
  text-decoration: none;
  top: 0;
  width: 100%;
  bottom: 0;
  padding-right: 10px;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity .25s linear;
}
.select2-search-choice .select2-search-choice-close:after {
  content: "\e609";
  font-family: "Flat-UI-Icons";
  line-height: 27px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-search-field input[type="text"] {
  color: #34495e;
  font-size: 14px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: auto;
  max-width: inherit;
  min-width: 80px;
  vertical-align: top;
  height: 29px;
}
.select2-search-field:first-child input[type="text"] {
  height: 23px;
  margin: 3px 0 5px;
}
.select2-container-multi.multiselect-default {
  border-color: #bdc3c7;
}
.select2-container-multi.multiselect-default .select2-search-choice {
  background-color: #bdc3c7;
}
.select2-container-multi.multiselect-default .select2-search-choice:hover {
  background-color: #cacfd2;
}
.select2-container-multi.multiselect-primary {
  border-color: #1abc9c;
}
.select2-container-multi.multiselect-primary .select2-search-choice {
  background-color: #1abc9c;
}
.select2-container-multi.multiselect-primary .select2-search-choice:hover {
  background-color: #48c9b0;
}
.select2-container-multi.multiselect-info {
  border-color: #3498db;
}
.select2-container-multi.multiselect-info .select2-search-choice {
  background-color: #3498db;
}
.select2-container-multi.multiselect-info .select2-search-choice:hover {
  background-color: #5dade2;
}
.select2-container-multi.multiselect-danger {
  border-color: #e74c3c;
}
.select2-container-multi.multiselect-danger .select2-search-choice {
  background-color: #e74c3c;
}
.select2-container-multi.multiselect-danger .select2-search-choice:hover {
  background-color: #ec7063;
}
.select2-container-multi.multiselect-success {
  border-color: #2ecc71;
}
.select2-container-multi.multiselect-success .select2-search-choice {
  background-color: #2ecc71;
}
.select2-container-multi.multiselect-success .select2-search-choice:hover {
  background-color: #58d68d;
}
.select2-container-multi.multiselect-warning {
  border-color: #f1c40f;
}
.select2-container-multi.multiselect-warning .select2-search-choice {
  background-color: #f1c40f;
}
.select2-container-multi.multiselect-warning .select2-search-choice:hover {
  background-color: #f4d313;
}
.select2-container-multi.multiselect-inverse {
  border-color: #34495e;
}
.select2-container-multi.multiselect-inverse .select2-search-choice {
  background-color: #34495e;
}
.select2-container-multi.multiselect-inverse .select2-search-choice:hover {
  background-color: #415b76;
}
.select2-drop {
  min-width: 220px;
  margin-top: 9px;
  visibility: visible;
  opacity: 1;
  filter: none;
  border-radius: 4px;
  font-size: 14px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  transition: none;
}
.select2-drop.select2-drop-above {
  margin-top: -9px;
}
.select2-drop.select2-drop-auto-width {
  width: auto;
}
.select2-drop.show-select-search .select2-search {
  display: block;
}
.select2-drop.show-select-search .select2-search + .select2-results > li:first-child .select2-result-label {
  border-radius: 0;
}
.select2-drop .select2-results {
  padding: 0;
  margin: 0;
  list-style: none;
}
.select2-drop .select2-results > li:first-child > .select2-result-label {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.select2-drop .select2-results > li:last-child > .select2-result-label {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.select2-drop .select2-result-sub {
  padding: 0;
  margin: 0;
  list-style: none;
}
.select2-drop .select2-result-sub > li:last-child > .select2-result-label {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.select2-drop .select2-no-results {
  padding: 8px 15px;
}
.select2-drop .select2-result-label {
  line-height: 1.429;
  padding: 8px 16px;
  user-select: none;
  transition: background-color 0.25s, color 0.25s;
}
.select2-drop .select2-result-selectable .select2-result-label {
  color: rgba(52, 73, 94, 0.85);
  cursor: pointer;
}
.select2-drop .select2-result-selectable .select2-result-label:focus,
.select2-drop .select2-result-selectable .select2-result-label:hover,
.select2-drop .select2-result-selectable .select2-result-label:active {
  background-color: #e1e4e7;
  color: inherit;
  outline: none;
}
.select2-drop .select2-disabled {
  cursor: default;
  color: rgba(52, 73, 94, 0.95);
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.select2-drop .select2-disabled:focus,
.select2-drop .select2-disabled:hover,
.select2-drop .select2-disabled:active {
  background: none !important;
}
.select2-drop .select2-highlighted > .select2-result-label {
  background: #1abc9c;
  color: #ffffff;
}
.select2-drop .select2-result-with-children > .select2-result-label {
  font-size: 13px;
  text-transform: uppercase;
  color: rgba(52, 73, 94, 0.6);
  margin-top: 5px;
}
.select2-drop .select2-result-with-children + .select2-result-with-children > .select2-result-label {
  margin-top: 11px;
}
.select2-results {
  max-height: 200px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.select2-search {
  padding: 8px 6px;
  width: 100%;
  display: none;
}
.select2-search input[type="text"] {
  width: 100%;
  height: auto !important;
}
.select-inverse-dropdown {
  background-color: #34495e;
  color: rgba(255, 255, 255, 0.75);
}
.select-inverse-dropdown .select2-results .select2-result-label {
  color: #ffffff;
}
.select-inverse-dropdown .select2-results .select2-result-label:focus,
.select-inverse-dropdown .select2-results .select2-result-label:hover,
.select-inverse-dropdown .select2-results .select2-result-label:active {
  background: #2c3e50;
}
.select-inverse-dropdown .select2-results.select2-disabled .select2-result-label:hover {
  color: #ffffff;
}
.select-inverse-dropdown .select2-result-with-children > .select2-result-label {
  color: rgba(255, 255, 255, 0.6);
}
.select-inverse-dropdown .select2-result-with-children > .select2-result-label:hover {
  color: #ffffff;
  background: none !important;
}
.select2-drop-multi {
  border-radius: 6px;
}
.select2-drop-multi .select2-results {
  padding: 2px 0;
}
.select2-drop-multi .select2-result {
  padding: 2px 4px;
}
.select2-drop-multi .select2-result-label {
  border-radius: 4px;
}
.select2-drop-multi .select2-selected {
  display: none;
}
.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0 !important;
  top: 0 !important;
}
.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0 !important;
  top: 0 !important;
}
.select2-display-none {
  display: none;
}
.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}
.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 9998;
  /* styles required for IE to work */
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tile {
  background-color: #eff0f2;
  border-radius: 6px;
  padding: 14px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}
.tile .tile-hot-ribbon {
  display: block;
  position: absolute;
  right: -4px;
  top: -4px;
  width: 82px;
}
.tile p {
  font-size: 15px;
  margin-bottom: 33px;
}
.tile-image {
  height: 100px;
  margin: 31px 0 27px;
  vertical-align: bottom;
}
.tile-image.big-illustration {
  height: 111px;
  margin-top: 20px;
  width: 112px;
}
.tile-title {
  font-size: 20px;
  margin: 0;
}
.navbar {
  font-size: 16px;
  min-height: 53px;
  margin-bottom: 30px;
  border: none;
  border-radius: 6px;
}
@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}
.navbar-collapse {
  box-shadow: none;
  padding-right: 21px;
  padding-left: 21px;
}
.navbar-collapse .navbar-form:first-child {
  border: none;
}
@media (min-width: 768px) {
  .navbar-collapse .navbar-nav.navbar-left:first-child {
    margin-left: -21px;
  }
  .navbar-collapse .navbar-nav.navbar-left:first-child > li:first-child a {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .navbar-collapse .navbar-nav.navbar-right:last-child {
    margin-right: -21px;
  }
  .navbar-collapse .navbar-nav.navbar-right:last-child > .dropdown:last-child > a {
    border-radius: 0 6px 6px 0;
  }
  .navbar-fixed-top .navbar-collapse .navbar-form.navbar-right:last-child,
  .navbar-fixed-bottom .navbar-collapse .navbar-form.navbar-right:last-child {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .navbar-collapse .navbar-nav.navbar-right:last-child {
    margin-bottom: 3px;
  }
}
.navbar .container,
.navbar .container-fluid {
  padding-left: 21px;
  padding-right: 21px;
}
.navbar .container > .navbar-header,
.navbar .container-fluid > .navbar-header,
.navbar .container > .navbar-collapse,
.navbar .container-fluid > .navbar-collapse {
  margin-right: -21px;
  margin-left: -21px;
}
@media (min-width: 768px) {
  .navbar .container > .navbar-header,
  .navbar .container-fluid > .navbar-header,
  .navbar .container > .navbar-collapse,
  .navbar .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}
.navbar-static-top {
  z-index: 1000;
  border-width: 0;
  border-radius: 0;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  z-index: 1030;
  border-radius: 0;
}
.navbar-fixed-top {
  border-width: 0;
}
.navbar-fixed-bottom {
  margin-bottom: 0;
  border-width: 0;
}
.navbar-brand {
  font-size: 24px;
  line-height: 1.042;
  height: 53px;
  font-weight: 700;
  padding: 14px 21px;
}
.navbar-brand > [class*="fui-"] {
  font-size: 19px;
  line-height: 1.263;
  vertical-align: top;
}
@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -21px;
  }
}
.navbar-toggle {
  border: none;
  color: #34495e;
  margin: 0 0 0 21px;
  padding: 0 21px;
  height: 53px;
  line-height: 53px;
}
.navbar-toggle:before {
  color: #16a085;
  content: "\e61a";
  font-family: "Flat-UI-Icons";
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color .25s linear;
}
.navbar-toggle:hover,
.navbar-toggle:focus {
  outline: none;
}
.navbar-toggle:hover:before,
.navbar-toggle:focus:before {
  color: #1abc9c;
}
.navbar-toggle .icon-bar {
  display: none;
}
@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}
.navbar-nav {
  margin: 0;
}
.navbar-nav > li > a {
  font-size: 16px;
  padding: 15px 21px;
  line-height: 23px;
  font-weight: 700;
}
.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus,
.navbar-nav .open > a:focus,
.navbar-nav .open > a:hover {
  background-color: transparent;
}
.navbar-nav [class^="fui-"] {
  line-height: 20px;
  position: relative;
  top: 1px;
}
.navbar-nav .visible-sm > [class^="fui-"],
.navbar-nav .visible-xs > [class^="fui-"] {
  margin-left: 12px;
}
@media (max-width: 767px) {
  .navbar-nav {
    margin: 0 -21px;
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 7px 15px 7px 31px !important;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 23px;
  }
  .navbar-nav > li > a {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
.navbar-input {
  height: 35px;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.4;
  border-radius: 6px;
}
select.navbar-input {
  height: 35px;
  line-height: 35px;
}
textarea.navbar-input,
select[multiple].navbar-input {
  height: auto;
}
.navbar-form {
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 19px;
  padding-left: 19px;
  padding-top: 9px;
  padding-bottom: 9px;
}
@media (max-width: 767px) {
  .navbar-form {
    margin: 3px -21px;
    width: auto;
  }
}
.navbar-form .form-control,
.navbar-form .input-group-addon,
.navbar-form .btn,
.navbar-form .select2-search input[type="text"] {
  height: 35px;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.4;
  border-radius: 6px;
}
select.navbar-form .form-control,
select.navbar-form .input-group-addon,
select.navbar-form .btn,
select.navbar-form .select2-search input[type="text"] {
  height: 35px;
  line-height: 35px;
}
textarea.navbar-form .form-control,
textarea.navbar-form .input-group-addon,
textarea.navbar-form .btn,
select[multiple].navbar-form .form-control,
select[multiple].navbar-form .input-group-addon,
select[multiple].navbar-form .btn,
textarea.navbar-form .select2-search input[type="text"],
select[multiple].navbar-form .select2-search input[type="text"] {
  height: auto;
}
.navbar-form .btn {
  margin: 0;
}
.navbar-form .input-group .form-control:first-child,
.navbar-form .input-group-addon:first-child,
.navbar-form .input-group-btn:first-child > .btn,
.navbar-form .input-group-btn:first-child > .dropdown-toggle,
.navbar-form .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.navbar-form .input-group .select2-search input[type="text"]:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.navbar-form .input-group .form-control:last-child,
.navbar-form .input-group-addon:last-child,
.navbar-form .input-group-btn:last-child > .btn,
.navbar-form .input-group-btn:last-child > .dropdown-toggle,
.navbar-form .input-group-btn:first-child > .btn:not(:first-child),
.navbar-form .input-group .select2-search input[type="text"]:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.navbar-form .form-control,
.navbar-form .select2-search input[type="text"] {
  font-size: 15px;
  border-radius: 5px;
  display: table-cell;
}
.navbar-form .form-group ~ .btn {
  font-size: 15px;
  border-radius: 5px;
  margin-left: 5px;
}
.navbar-form .form-group + .btn {
  margin-right: 5px;
}
@media (min-width: 768px) {
  .navbar-form .input-group {
    width: 195px;
  }
}
@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 7px;
  }
  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
  .navbar-form .form-group + .btn {
    margin-left: 0;
  }
}
.navbar-nav > li > .dropdown-menu {
  min-width: 100%;
  margin-top: 9px;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .navbar-nav > li.open > .dropdown-menu {
    margin-top: 0 !important;
  }
}
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.navbar-nav > .open > .dropdown-toggle,
.navbar-nav > .open > .dropdown-toggle:focus,
.navbar-nav > .open > .dropdown-toggle:hover {
  background-color: transparent;
}
.navbar-text {
  font-size: 16px;
  line-height: 1.438;
  color: #34495e;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .navbar-text {
    margin-left: 21px;
    margin-right: 21px;
  }
  .navbar-text.navbar-right:last-child {
    margin-right: 0;
  }
}
.navbar-btn {
  margin-top: 6px;
  margin-bottom: 6px;
}
.navbar-btn.btn-sm {
  margin-top: 9px;
  margin-bottom: 8px;
}
.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}
.navbar-unread,
.navbar-new {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  background-color: #1abc9c;
  border-radius: 50%;
  color: #ffffff;
  font-size: 0;
  font-weight: 700;
  height: 6px;
  line-height: 1;
  position: absolute;
  right: 12px;
  text-align: center;
  top: 35%;
  width: 6px;
  z-index: 10;
}
@media (max-width: 768px) {
  .navbar-unread,
  .navbar-new {
    position: static;
    float: right;
    margin: 0 0 0 10px;
  }
}
.active .navbar-unread,
.active .navbar-new {
  background-color: #ffffff;
  display: none;
}
.navbar-new {
  background-color: #e74c3c;
  font-size: 12px;
  height: 18px;
  line-height: 17px;
  margin: -6px -10px;
  min-width: 18px;
  padding: 0 1px;
  width: auto;
  -webkit-font-smoothing: subpixel-antialiased;
}
.navbar-default {
  background-color: #ecf0f1;
}
.navbar-default .navbar-brand {
  color: #34495e;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #1abc9c;
  background-color: transparent;
}
.navbar-default .navbar-toggle:before {
  color: #34495e;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: transparent;
}
.navbar-default .navbar-toggle:hover:before,
.navbar-default .navbar-toggle:focus:before {
  color: #1abc9c;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e5e9ea;
  border-width: 2px;
}
.navbar-default .navbar-nav > li > a {
  color: #34495e;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #1abc9c;
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #1abc9c;
  background-color: transparent;
}
.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
  color: #cccccc;
  background-color: transparent;
}
.navbar-default .navbar-nav > .dropdown > a .caret {
  border-top-color: #34495e;
  border-bottom-color: #34495e;
}
.navbar-default .navbar-nav > .active > a .caret {
  border-top-color: #1abc9c;
  border-bottom-color: #1abc9c;
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
  border-top-color: #1abc9c;
  border-bottom-color: #1abc9c;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: #1abc9c;
}
.navbar-default .navbar-nav > .open > a .caret,
.navbar-default .navbar-nav > .open > a:hover .caret,
.navbar-default .navbar-nav > .open > a:focus .caret {
  border-top-color: #1abc9c;
  border-bottom-color: #1abc9c;
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #34495e;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #1abc9c;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #1abc9c;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #cccccc;
    background-color: transparent;
  }
}
.navbar-default .navbar-form .form-control,
.navbar-default .navbar-form .select2-search input[type="text"] {
  border-color: transparent;
}
.navbar-default .navbar-form .form-control::-moz-placeholder,
.navbar-default .navbar-form .select2-search input[type="text"]::-moz-placeholder {
  color: #aeb6bf;
  opacity: 1;
}
.navbar-default .navbar-form .form-control:-ms-input-placeholder,
.navbar-default .navbar-form .select2-search input[type="text"]:-ms-input-placeholder {
  color: #aeb6bf;
}
.navbar-default .navbar-form .form-control::-webkit-input-placeholder,
.navbar-default .navbar-form .select2-search input[type="text"]::-webkit-input-placeholder {
  color: #aeb6bf;
}
.navbar-default .navbar-form .form-control:focus,
.navbar-default .navbar-form .select2-search input[type="text"]:focus {
  border-color: #1abc9c;
  color: #1abc9c;
}
.navbar-default .navbar-form .input-group-btn .btn {
  border-color: transparent;
  color: #919ba4;
}
.navbar-default .navbar-form .input-group.focus .form-control,
.navbar-default .navbar-form .input-group.focus .input-group-btn .btn,
.navbar-default .navbar-form .input-group.focus .select2-search input[type="text"] {
  border-color: #1abc9c;
  color: #1abc9c;
}
.navbar-default .navbar-text {
  color: #34495e;
}
.navbar-default .navbar-link {
  color: #34495e;
}
.navbar-default .navbar-link:hover {
  color: #1abc9c;
}
.navbar-default .btn-link {
  color: #34495e;
}
.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
  color: #1abc9c;
}
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:focus {
  color: #cccccc;
}
.navbar-inverse {
  background-color: #34495e;
}
.navbar-inverse .navbar-brand {
  color: #ffffff;
}
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
  color: #1abc9c;
  background-color: transparent;
}
.navbar-inverse .navbar-toggle:before {
  color: #ffffff;
}
.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: transparent;
}
.navbar-inverse .navbar-toggle:hover:before,
.navbar-inverse .navbar-toggle:focus:before {
  color: #1abc9c;
}
.navbar-inverse .navbar-collapse {
  border-color: #2f4154;
  border-width: 2px;
}
.navbar-inverse .navbar-nav > li > a {
  color: #ffffff;
}
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  color: #1abc9c;
  background-color: transparent;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  color: #ffffff;
  background-color: #1abc9c;
}
.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444444;
  background-color: transparent;
}
.navbar-inverse .navbar-nav > .dropdown > a:hover .caret,
.navbar-inverse .navbar-nav > .dropdown > a:focus .caret {
  border-top-color: #1abc9c;
  border-bottom-color: #1abc9c;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #1abc9c;
  color: #ffffff;
  border-left-color: transparent;
}
.navbar-inverse .navbar-nav > .open > a .caret,
.navbar-inverse .navbar-nav > .open > a:hover .caret,
.navbar-inverse .navbar-nav > .open > a:focus .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.navbar-inverse .navbar-nav > .dropdown > a .caret {
  border-top-color: #4b6075;
  border-bottom-color: #4b6075;
}
.navbar-inverse .navbar-nav > .open > .dropdown-menu {
  background-color: #34495e;
  padding: 3px 4px;
}
.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a {
  color: #e1e4e7;
  border-radius: 4px;
  padding: 6px 9px;
}
.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a:hover,
.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a:focus {
  color: #ffffff;
  background-color: #1abc9c;
}
.navbar-inverse .navbar-nav > .open > .dropdown-menu > .divider {
  background-color: #2f4154;
  height: 2px;
  margin-left: -4px;
  margin-right: -4px;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-nav > li > a {
    border-left-width: 0;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #ffffff;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #1abc9c;
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #ffffff;
    background-color: #1abc9c;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444444;
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav .dropdown-menu .divider {
    background-color: #2f4154;
  }
}
.navbar-inverse .navbar-form .form-control,
.navbar-inverse .navbar-form .select2-search input[type="text"] {
  color: #536a81;
  border-color: transparent;
  background-color: #293a4a;
}
.navbar-inverse .navbar-form .form-control::-moz-placeholder,
.navbar-inverse .navbar-form .select2-search input[type="text"]::-moz-placeholder {
  color: #536a81;
  opacity: 1;
}
.navbar-inverse .navbar-form .form-control:-ms-input-placeholder,
.navbar-inverse .navbar-form .select2-search input[type="text"]:-ms-input-placeholder {
  color: #536a81;
}
.navbar-inverse .navbar-form .form-control::-webkit-input-placeholder,
.navbar-inverse .navbar-form .select2-search input[type="text"]::-webkit-input-placeholder {
  color: #536a81;
}
.navbar-inverse .navbar-form .form-control:focus,
.navbar-inverse .navbar-form .select2-search input[type="text"]:focus {
  border-color: #1abc9c;
  color: #1abc9c;
}
.navbar-inverse .navbar-form .btn {
  color: #ffffff;
  background-color: #1abc9c;
}
.navbar-inverse .navbar-form .btn:hover,
.navbar-inverse .navbar-form .btn.hover,
.navbar-inverse .navbar-form .btn:focus,
.navbar-inverse .navbar-form .btn:active,
.navbar-inverse .navbar-form .btn.active,
.open > .dropdown-toggle.navbar-inverse .navbar-form .btn {
  color: #ffffff;
  background-color: #48c9b0;
  border-color: #48c9b0;
}
.navbar-inverse .navbar-form .btn:active,
.navbar-inverse .navbar-form .btn.active,
.open > .dropdown-toggle.navbar-inverse .navbar-form .btn {
  background: #16a085;
  border-color: #16a085;
}
.navbar-inverse .navbar-form .btn.disabled,
.navbar-inverse .navbar-form .btn[disabled],
fieldset[disabled] .navbar-inverse .navbar-form .btn,
.navbar-inverse .navbar-form .btn.disabled:hover,
.navbar-inverse .navbar-form .btn[disabled]:hover,
fieldset[disabled] .navbar-inverse .navbar-form .btn:hover,
.navbar-inverse .navbar-form .btn.disabled.hover,
.navbar-inverse .navbar-form .btn[disabled].hover,
fieldset[disabled] .navbar-inverse .navbar-form .btn.hover,
.navbar-inverse .navbar-form .btn.disabled:focus,
.navbar-inverse .navbar-form .btn[disabled]:focus,
fieldset[disabled] .navbar-inverse .navbar-form .btn:focus,
.navbar-inverse .navbar-form .btn.disabled:active,
.navbar-inverse .navbar-form .btn[disabled]:active,
fieldset[disabled] .navbar-inverse .navbar-form .btn:active,
.navbar-inverse .navbar-form .btn.disabled.active,
.navbar-inverse .navbar-form .btn[disabled].active,
fieldset[disabled] .navbar-inverse .navbar-form .btn.active {
  background-color: #bdc3c7;
  border-color: #1abc9c;
}
.navbar-inverse .navbar-form .btn .badge {
  color: #1abc9c;
  background-color: #ffffff;
}
.navbar-inverse .navbar-form .input-group-btn .btn {
  border-color: transparent;
  background-color: #293a4a;
  color: #526a82;
}
.navbar-inverse .navbar-form .input-group.focus .form-control,
.navbar-inverse .navbar-form .input-group.focus .input-group-btn .btn,
.navbar-inverse .navbar-form .input-group.focus .select2-search input[type="text"] {
  border-color: #1abc9c;
  color: #1abc9c;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-form {
    border-color: #2f4154;
    border-width: 2px 0;
  }
}
.navbar-inverse .navbar-text {
  color: #ffffff;
}
.navbar-inverse .navbar-text a {
  color: #ffffff;
}
.navbar-inverse .navbar-text a:hover,
.navbar-inverse .navbar-text a:focus {
  color: #1abc9c;
}
.navbar-inverse .navbar-btn {
  color: #ffffff;
  background-color: #1abc9c;
}
.navbar-inverse .navbar-btn:hover,
.navbar-inverse .navbar-btn.hover,
.navbar-inverse .navbar-btn:focus,
.navbar-inverse .navbar-btn:active,
.navbar-inverse .navbar-btn.active,
.open > .dropdown-toggle.navbar-inverse .navbar-btn {
  color: #ffffff;
  background-color: #48c9b0;
  border-color: #48c9b0;
}
.navbar-inverse .navbar-btn:active,
.navbar-inverse .navbar-btn.active,
.open > .dropdown-toggle.navbar-inverse .navbar-btn {
  background: #16a085;
  border-color: #16a085;
}
.navbar-inverse .navbar-btn.disabled,
.navbar-inverse .navbar-btn[disabled],
fieldset[disabled] .navbar-inverse .navbar-btn,
.navbar-inverse .navbar-btn.disabled:hover,
.navbar-inverse .navbar-btn[disabled]:hover,
fieldset[disabled] .navbar-inverse .navbar-btn:hover,
.navbar-inverse .navbar-btn.disabled.hover,
.navbar-inverse .navbar-btn[disabled].hover,
fieldset[disabled] .navbar-inverse .navbar-btn.hover,
.navbar-inverse .navbar-btn.disabled:focus,
.navbar-inverse .navbar-btn[disabled]:focus,
fieldset[disabled] .navbar-inverse .navbar-btn:focus,
.navbar-inverse .navbar-btn.disabled:active,
.navbar-inverse .navbar-btn[disabled]:active,
fieldset[disabled] .navbar-inverse .navbar-btn:active,
.navbar-inverse .navbar-btn.disabled.active,
.navbar-inverse .navbar-btn[disabled].active,
fieldset[disabled] .navbar-inverse .navbar-btn.active {
  background-color: #bdc3c7;
  border-color: #1abc9c;
}
.navbar-inverse .navbar-btn .badge {
  color: #1abc9c;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .navbar-embossed > .navbar-collapse {
    border-radius: 6px;
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  }
  .navbar-embossed.navbar-inverse .navbar-nav .active > a,
  .navbar-embossed.navbar-inverse .navbar-nav .open > a {
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  }
}
.navbar-lg {
  min-height: 76px;
}
.navbar-lg .navbar-brand {
  line-height: 1;
  height: 76px;
  padding-top: 26px;
  padding-bottom: 26px;
}
.navbar-lg .navbar-brand > [class*="fui-"] {
  font-size: 24px;
  line-height: 1;
}
.navbar-lg .navbar-nav > li > a {
  font-size: 15px;
  line-height: 1.6;
}
@media (min-width: 768px) {
  .navbar-lg .navbar-nav > li > a {
    padding-top: 26px;
    padding-bottom: 26px;
  }
}
.navbar-lg .navbar-toggle {
  height: 76px;
  line-height: 76px;
}
.navbar-lg .navbar-form {
  padding-top: 20.5px;
  padding-bottom: 20.5px;
}
.navbar-lg .navbar-text {
  padding-top: 26.5px;
  padding-bottom: 26.5px;
}
.navbar-lg .navbar-btn {
  margin-top: 17.5px;
  margin-bottom: 17.5px;
}
.navbar-lg .navbar-btn.btn-sm {
  margin-top: 20.5px;
  margin-bottom: 20.5px;
}
.navbar-lg .navbar-btn.btn-xs {
  margin-top: 25.5px;
  margin-bottom: 25.5px;
}
.bootstrap-switch {
  font-size: 15px;
  line-height: 29px;
  display: inline-block;
  cursor: pointer;
  border-radius: 30px;
  position: relative;
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
  width: 80px;
  height: 29px;
  -webkit-mask-box-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgODAgMjkiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDgwIDI5IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik04MCwxNC41YzAsOC02LjUsMTQuNS0xNC41LDE0LjVoLTUxQzYuNSwyOSwwLDIyLjUsMCwxNC41bDAsMEMwLDYuNSw2LjUsMCwxNC41LDBoNTFDNzMuNSwwLDgwLDYuNSw4MCwxNC41TDgwLDE0LjV6Ii8+DQo8L3N2Zz4NCg==) 0 0 stretch;
  user-select: none;
}
.bootstrap-switch > div {
  display: inline-block;
  width: 132px;
  border-radius: 30px;
  transform: translate3d(0, 0, 0);
}
.bootstrap-switch > div > span {
  font-weight: 700;
  line-height: 19px;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  z-index: 1;
  width: 66px;
  transition: box-shadow 0.25s ease-out;
}
.bootstrap-switch > div > span > [class^="fui-"] {
  text-indent: 0;
}
.bootstrap-switch > div > label {
  cursor: pointer;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  font-size: 0;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 200;
  opacity: 0;
  filter: alpha(opacity=0);
}
.bootstrap-switch input[type="radio"],
.bootstrap-switch input[type="checkbox"] {
  position: absolute !important;
  margin: 0;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
}
.bootstrap-switch-handle-on {
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-default {
  box-shadow: inset 0 0 transparent, -16px 0 0 #bdc3c7;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-default:before {
  border-color: #bdc3c7;
  background-color: #7f8c9a;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-primary {
  box-shadow: inset 0 0 transparent, -16px 0 0 #34495e;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-primary:before {
  border-color: #34495e;
  background-color: #1abc9c;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-success {
  box-shadow: inset 0 0 transparent, -16px 0 0 #2ecc71;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-success:before {
  border-color: #2ecc71;
  background-color: #ffffff;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-warning {
  box-shadow: inset 0 0 transparent, -16px 0 0 #f1c40f;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-warning:before {
  border-color: #f1c40f;
  background-color: #ffffff;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-info {
  box-shadow: inset 0 0 transparent, -16px 0 0 #3498db;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-info:before {
  border-color: #3498db;
  background-color: #ffffff;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-danger {
  box-shadow: inset 0 0 transparent, -16px 0 0 #e74c3c;
}
.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-danger:before {
  border-color: #e74c3c;
  background-color: #ffffff;
}
.bootstrap-switch-handle-off {
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}
.bootstrap-switch-handle-off:before {
  display: inline-block;
  content: " ";
  border: 4px solid transparent;
  border-radius: 50%;
  text-align: center;
  vertical-align: top;
  padding: 0;
  height: 29px;
  width: 29px;
  position: absolute;
  top: 0;
  left: 51px;
  z-index: 100;
  background-clip: padding-box;
  transition: border-color 0.25s ease-out, background-color 0.25s ease-out;
}
.bootstrap-switch-animate > div {
  transition: margin-left 0.25s ease-out;
}
.bootstrap-switch-on > div {
  margin-left: 0;
}
.bootstrap-switch-off > div {
  margin-left: -51px;
}
.bootstrap-switch-disabled,
.bootstrap-switch-readonly {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default;
}
.bootstrap-switch-disabled > div > span,
.bootstrap-switch-readonly > div > span,
.bootstrap-switch-disabled > div > label,
.bootstrap-switch-readonly > div > label {
  cursor: default !important;
}
.bootstrap-switch-focused {
  outline: 0;
}
.bootstrap-switch-default {
  color: #ffffff;
  background-color: #bdc3c7;
}
.bootstrap-switch-default ~ .bootstrap-switch-handle-off:before {
  background-color: #7f8c9a;
  border-color: #bdc3c7;
}
.bootstrap-switch-on .bootstrap-switch-default ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #bdc3c7;
}
.bootstrap-switch-primary {
  color: #1abc9c;
  background-color: #34495e;
}
.bootstrap-switch-primary ~ .bootstrap-switch-handle-off:before {
  background-color: #1abc9c;
  border-color: #34495e;
}
.bootstrap-switch-on .bootstrap-switch-primary ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #34495e;
}
.bootstrap-switch-info {
  color: #ffffff;
  background-color: #3498db;
}
.bootstrap-switch-info ~ .bootstrap-switch-handle-off:before {
  background-color: #ffffff;
  border-color: #3498db;
}
.bootstrap-switch-on .bootstrap-switch-info ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #3498db;
}
.bootstrap-switch-success {
  color: #ffffff;
  background-color: #2ecc71;
}
.bootstrap-switch-success ~ .bootstrap-switch-handle-off:before {
  background-color: #ffffff;
  border-color: #2ecc71;
}
.bootstrap-switch-on .bootstrap-switch-success ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #2ecc71;
}
.bootstrap-switch-warning {
  color: #ffffff;
  background-color: #f1c40f;
}
.bootstrap-switch-warning ~ .bootstrap-switch-handle-off:before {
  background-color: #ffffff;
  border-color: #f1c40f;
}
.bootstrap-switch-on .bootstrap-switch-warning ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #f1c40f;
}
.bootstrap-switch-danger {
  color: #ffffff;
  background-color: #e74c3c;
}
.bootstrap-switch-danger ~ .bootstrap-switch-handle-off:before {
  background-color: #ffffff;
  border-color: #e74c3c;
}
.bootstrap-switch-on .bootstrap-switch-danger ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #e74c3c;
}
.bootstrap-switch-square .bootstrap-switch {
  -webkit-mask-box-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgODAgMjkiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDgwIDI5IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik04MCwyNWMwLDIuMi0xLjgsNC00LDRINGMtMi4yLDAtNC0xLjgtNC00VjRjMC0yLjIsMS44LTQsNC00aDcyYzIuMiwwLDQsMS44LDQsNFYyNXoiLz4NCjwvc3ZnPg0K) 0 0 stretch;
  border-radius: 4px;
}
.bootstrap-switch-square .bootstrap-switch > div {
  border-radius: 4px;
}
.bootstrap-switch-square .bootstrap-switch .bootstrap-switch-handle-on {
  text-indent: -15px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.bootstrap-switch-square .bootstrap-switch .bootstrap-switch-handle-off {
  text-indent: 15px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.bootstrap-switch-square .bootstrap-switch .bootstrap-switch-handle-off:before {
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch-square .bootstrap-switch-off .bootstrap-switch-handle-off:before {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.share {
  background-color: #eff0f2;
  position: relative;
  border-radius: 6px;
}
.share ul {
  list-style-type: none;
  margin: 0;
  padding: 15px;
}
.share li {
  font-size: 15px;
  line-height: 1.4;
  padding-top: 11px;
}
.share li:before,
.share li:after {
  content: " ";
  display: table;
}
.share li:after {
  clear: both;
}
.share li:first-child {
  padding-top: 0;
}
.share .toggle {
  float: right;
  margin: 0;
}
.share .btn {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.share-label {
  float: left;
  font-size: 15px;
  line-height: 1.4;
  padding-top: 5px;
  width: 50%;
}
.video-js {
  background-color: transparent;
  position: relative;
  padding-bottom: 47px;
  font-size: 0;
  vertical-align: middle;
  overflow: hidden;
  backface-visibility: hidden;
  border-top-radius: 6px;
  width: 100% !important;
  height: auto !important;
}
.video-js .vjs-tech {
  height: 100%;
  width: 100%;
  display: block;
}
.video-js::-moz-full-screen {
  position: absolute;
}
.video-js::-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}
.vjs-fullscreen {
  position: fixed;
  overflow: hidden;
  z-index: 10000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  border-top-radius: 0;
}
.vjs-fullscreen .vjs-control-bar {
  margin-top: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.vjs-fullscreen .vjs-tech {
  background-color: #000000;
}
.vjs-poster {
  margin: 0 auto;
  padding: 0;
  cursor: pointer;
  position: relative;
  width: 100%;
  max-height: 100%;
  border-top-radius: 6px;
}
.vjs-control-bar {
  position: relative;
  height: 47px;
  color: #ffffff;
  background: #2c3e50;
  margin-top: -1px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  user-select: none;
}
.vjs-control-bar.vjs-fade-out {
  visibility: visible !important;
  opacity: 1 !important;
}
.vjs-text-track-display {
  text-align: center;
  position: absolute;
  bottom: 4em;
  left: 1em;
  right: 1em;
  font-family: "Lato", Helvetica, Arial, sans-serif;
}
.vjs-text-track {
  display: none;
  color: #ffffff;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: .1em;
  background-color: rgba(0, 0, 0, 0.5);
}
.vjs-subtitles {
  color: #ffffff;
}
.vjs-captions {
  color: #fc6;
}
.vjs-tt-cue {
  display: block;
}
.vjs-fade-in {
  visibility: visible !important;
  opacity: 1 !important;
  transition: visibility 0s linear 0s, opacity .3s linear;
}
.vjs-fade-out {
  visibility: hidden !important;
  opacity: 0 !important;
  transition: visibility 0s linear 1.5s, opacity 1.5s linear;
}
.vjs-control {
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}
.vjs-control:focus {
  outline: 0;
}
.vjs-control > div {
  background-position: center;
  background-repeat: no-repeat;
}
.vjs-control-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.vjs-play-control {
  cursor: pointer;
  height: 47px;
  width: 58px;
}
.vjs-play-control > div {
  position: relative;
  height: 47px;
}
.vjs-play-control > div:before,
.vjs-play-control > div:after {
  position: absolute;
  font-family: "Flat-UI-Icons";
  color: #1abc9c;
  font-size: 16px;
  top: 38%;
  left: 50%;
  margin: -0.5em 0 0 -0.5em;
  -webkit-font-smoothing: antialiased;
  transition: color .25s, opacity .25s;
}
.vjs-play-control > div:after {
  content: "\e615";
}
.vjs-play-control > div:before {
  content: "\e616";
}
.vjs-paused .vjs-play-control:hover > div:before {
  color: #16a085;
}
.vjs-paused .vjs-play-control > div:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.vjs-paused .vjs-play-control > div:before {
  opacity: 1;
  filter: none;
}
.vjs-playing .vjs-play-control:hover > div:after {
  color: #16a085;
}
.vjs-playing .vjs-play-control > div:after {
  opacity: 1;
  filter: none;
}
.vjs-playing .vjs-play-control > div:before {
  opacity: 0;
  filter: alpha(opacity=0);
}
.vjs-rewind-control {
  width: 5em;
  cursor: pointer !important;
}
.vjs-rewind-control > div {
  width: 19px;
  height: 16px;
  background: none transparent;
  margin: .5em auto 0;
}
.vjs-mute-control {
  float: right;
  margin: 14px 0;
  cursor: pointer !important;
}
.vjs-mute-control:hover > div,
.vjs-mute-control:focus > div {
  color: #57718b;
}
.vjs-mute-control > div {
  height: 18px;
  color: #475d72;
}
.vjs-mute-control > div:after,
.vjs-mute-control > div:before {
  font-family: "Flat-UI-Icons";
  font-size: 16px;
  line-height: 18px;
  position: absolute;
  left: 50%;
  margin: 0 0 0 -0.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color .25s, opacity .25s;
}
.vjs-mute-control > div:after {
  content: "\e617";
}
.vjs-mute-control > div:before {
  content: "\e618";
  opacity: 0;
  filter: alpha(opacity=0);
}
.vjs-mute-control.vjs-vol-0 > div:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.vjs-mute-control.vjs-vol-0 > div:before {
  opacity: 1;
  filter: none;
}
.vjs-volume-control,
.vjs-volume-level,
.vjs-volume-handle,
.vjs-volume-bar {
  display: none;
}
.vjs-progress-control {
  height: 12px;
  position: absolute;
  left: 60px;
  right: 160px;
  width: auto;
  top: 18px;
  background: #425669;
  border-radius: 32px;
}
.vjs-progress-holder {
  position: relative;
  cursor: pointer !important;
  padding: 0;
  margin: 0;
  height: 12px;
}
.vjs-play-progress,
.vjs-load-progress {
  display: block;
  height: 12px;
  margin: 0;
  padding: 0;
  border-radius: 32px;
}
.vjs-play-progress {
  background: #1abc9c;
  left: -1px;
  position: absolute;
  top: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.vjs-load-progress {
  background: #d6dbdf;
}
.vjs-load-progress[style*="100%"],
.vjs-load-progress[style*="99%"] {
  border-radius: 32px;
}
.vjs-seek-handle {
  background-color: #16a085;
  width: 18px;
  height: 18px;
  top: 0;
  position: absolute;
  margin: -3px 0 0 -3px;
  border-radius: 50%;
  transition: background-color 0.25s;
}
.vjs-seek-handle[style*="95."] {
  margin-left: 3px;
}
.vjs-seek-handle[style="left: 0%;"] {
  margin-left: -2px;
}
.vjs-seek-handle:hover,
.vjs-seek-handle:focus {
  background-color: #148d75;
}
.vjs-seek-handle:active {
  background-color: #117a65;
}
.vjs-time-controls {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 13px;
  line-height: normal;
  width: auto;
  height: auto;
  position: absolute;
}
.vjs-time-divider {
  color: #5d6d7e;
  font-size: 14px;
  position: absolute;
  right: 114px;
  top: 11px;
}
.vjs-remaining-time {
  display: none;
}
.vjs-current-time {
  right: 122px;
  top: 16px;
}
.vjs-duration {
  color: #5d6d7e;
  right: 85px;
  top: 16px;
}
.vjs-fullscreen-control {
  cursor: pointer;
  float: right;
  margin: 14px 15px;
}
.vjs-fullscreen-control:hover > div,
.vjs-fullscreen-control:focus > div {
  color: #57718b;
}
.vjs-fullscreen-control > div {
  height: 18px;
  color: #475d72;
}
.vjs-fullscreen-control > div:before {
  font-family: "Flat-UI-Icons";
  content: "\e619";
  font-size: 16px;
  line-height: 18px;
  position: absolute;
  left: 50%;
  margin: 0 0 0 -0.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color .25s, opacity .25s;
}
.vjs-menu-button {
  display: none !important;
}
.vjs-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ebedee;
  display: none;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  margin: -8px 0 0 -8px;
  animation: sharp 2s ease infinite;
}
@-webkit-keyframes sharp {
  0% {
    background-color: #e74c3c;
    border-radius: 10px;
    transform: rotate(0deg);
  }
  50% {
    background-color: #ebedee;
    border-radius: 0;
    transform: rotate(180deg);
  }
  100% {
    background-color: #e74c3c;
    border-radius: 10px;
    transform: rotate(360deg);
  }
}
@-moz-keyframes sharp {
  0% {
    background-color: #e74c3c;
    border-radius: 10px;
    transform: rotate(0deg);
  }
  50% {
    background-color: #ebedee;
    border-radius: 0;
    transform: rotate(180deg);
  }
  100% {
    background-color: #e74c3c;
    border-radius: 10px;
    transform: rotate(360deg);
  }
}
@-o-keyframes sharp {
  0% {
    background-color: #e74c3c;
    border-radius: 10px;
    transform: rotate(0deg);
  }
  50% {
    background-color: #ebedee;
    border-radius: 0;
    transform: rotate(180deg);
  }
  100% {
    background-color: #e74c3c;
    border-radius: 10px;
    transform: rotate(360deg);
  }
}
@keyframes sharp {
  0% {
    background-color: #e74c3c;
    border-radius: 10px;
    transform: rotate(0deg);
  }
  50% {
    background-color: #ebedee;
    border-radius: 0;
    transform: rotate(180deg);
  }
  100% {
    background-color: #e74c3c;
    border-radius: 10px;
    transform: rotate(360deg);
  }
}
.todo {
  color: #798795;
  margin-bottom: 20px;
  border-radius: 6px;
}
.todo ul {
  background-color: #2c3e50;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: 0 0 6px 6px;
}
.todo li {
  background: #34495e;
  background-size: 20px 20px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.214;
  margin-top: 2px;
  padding: 18px 42px 21px 25px;
  position: relative;
  transition: .25s;
}
.todo li:first-child {
  margin-top: 0;
}
.todo li:last-child {
  border-radius: 0 0 6px 6px;
  padding-bottom: 21px;
}
.todo li.todo-done {
  background: transparent;
  color: #1abc9c;
}
.todo li.todo-done .todo-name {
  color: #1abc9c;
}
.todo li:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 22px;
  margin-top: -10px;
  background: #ffffff;
  border-radius: 50%;
}
.todo li.todo-done:after {
  content: "\e60a";
  font-family: 'Flat-UI-Icons';
  text-align: center;
  font-size: 12px;
  line-height: 21px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1abc9c;
  color: #2c3e50;
}
.todo-search {
  position: relative;
  background: #1abc9c;
  background-size: 16px 16px;
  border-radius: 6px 6px 0 0;
  color: #34495e;
  padding: 19px 25px 20px;
}
.todo-search:before {
  position: absolute;
  font-family: 'Flat-UI-Icons';
  content: "\e630";
  font-size: 16px;
  line-height: 17px;
  display: inline-block;
  top: 50%;
  left: 92%;
  margin: -0.5em 0 0 -1em;
}
.todo-search-field {
  background: none;
  border: none;
  color: #34495e;
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  line-height: 23px;
  padding: 5px 0;
  text-indent: 0;
  box-shadow: none;
  outline: none;
}
.todo-search-field::-moz-placeholder {
  color: #34495e;
  opacity: 1;
}
.todo-search-field:-ms-input-placeholder {
  color: #34495e;
}
.todo-search-field::-webkit-input-placeholder {
  color: #34495e;
}
.todo-icon {
  float: left;
  font-size: 24px;
  padding: 11px 22px 0 0;
}
.todo-content {
  padding-top: 1px;
  overflow: hidden;
}
.todo-name {
  color: #ffffff;
  font-size: 17px;
  margin: 1px 0 3px;
}
.pallete-item {
  width: 140px;
  float: left;
  margin: 0 0 20px 20px;
}
.palette {
  font-size: 14px;
  line-height: 1.214;
  color: #ffffff;
  margin: 0;
  padding: 15px;
  text-transform: uppercase;
}
.palette dt,
.palette dd {
  line-height: 1.429;
}
.palette dt {
  display: block;
  font-weight: bold;
  opacity: .8;
}
.palette dd {
  font-weight: 300;
  margin-left: 0;
  opacity: .8;
  -webkit-font-smoothing: subpixel-antialiased;
}
.palette-turquoise {
  background-color: #1abc9c;
}
.palette-green-sea {
  background-color: #16a085;
}
.palette-emerald {
  background-color: #2ecc71;
}
.palette-nephritis {
  background-color: #27ae60;
}
.palette-peter-river {
  background-color: #3498db;
}
.palette-belize-hole {
  background-color: #2980b9;
}
.palette-amethyst {
  background-color: #9b59b6;
}
.palette-wisteria {
  background-color: #8e44ad;
}
.palette-wet-asphalt {
  background-color: #34495e;
}
.palette-midnight-blue {
  background-color: #2c3e50;
}
.palette-sun-flower {
  background-color: #f1c40f;
}
.palette-orange {
  background-color: #f39c12;
}
.palette-carrot {
  background-color: #e67e22;
}
.palette-pumpkin {
  background-color: #d35400;
}
.palette-alizarin {
  background-color: #e74c3c;
}
.palette-pomegranate {
  background-color: #c0392b;
}
.palette-clouds {
  background-color: #ecf0f1;
}
.palette-silver {
  background-color: #bdc3c7;
}
.palette-concrete {
  background-color: #95a5a6;
}
.palette-asbestos {
  background-color: #7f8c8d;
}
.palette-clouds {
  color: #bdc3c7;
}
.palette-paragraph {
  color: #7f8c8d;
  font-size: 12px;
  line-height: 17px;
}
.palette-paragraph span {
  color: #bdc3c7;
}
.palette-headline {
  color: #7f8c8d;
  font-size: 13px;
  font-weight: 700;
  margin-top: -3px;
}
.login {
  background: url(../img/login/imac.png) 0 0 no-repeat;
  background-size: 940px 778px;
  color: #ffffff;
  margin-bottom: 77px;
  padding: 38px 38px 267px;
  position: relative;
}
.login-screen {
  background-color: #1abc9c;
  min-height: 473px;
  padding: 123px 199px 33px 306px;
}
.login-icon {
  left: 200px;
  position: absolute;
  top: 160px;
  width: 96px;
}
.login-icon > img {
  display: block;
  margin-bottom: 6px;
  width: 100%;
}
.login-icon > h4 {
  font-size: 17px;
  font-weight: 300;
  line-height: 34px;
  opacity: .95;
}
.login-icon > h4 small {
  color: inherit;
  display: block;
  font-size: inherit;
  font-weight: 700;
}
.login-form {
  background-color: #edeff1;
  padding: 24px 23px 20px;
  position: relative;
  border-radius: 6px;
}
.login-form .control-group {
  margin-bottom: 6px;
  position: relative;
}
.login-form .login-field {
  border-color: transparent;
  font-size: 17px;
  text-indent: 3px;
}
.login-form .login-field:focus {
  border-color: #1abc9c;
}
.login-form .login-field:focus + .login-field-icon {
  color: #1abc9c;
}
.login-form .login-field-icon {
  color: #bfc9ca;
  font-size: 16px;
  position: absolute;
  right: 15px;
  top: 3px;
  transition: all .25s;
}
.login-link {
  color: #bfc9ca;
  display: block;
  font-size: 13px;
  margin-top: 15px;
  text-align: center;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 2) {
  .login {
    background-image: url(../img/login/imac-2x.png);
  }
}
footer {
  background-color: #edeff1;
  color: #bac1c8;
  font-size: 15px;
  padding: 0;
}
footer a {
  color: #9aa4af;
  font-weight: 700;
}
footer p {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 10px;
}
.footer-title {
  margin: 0 0 22px;
  padding-top: 21px;
  font-size: 24px;
  line-height: 40px;
}
.footer-brand {
  display: block;
  margin-bottom: 26px;
  width: 220px;
}
.footer-brand img {
  width: 216px;
}
.footer-banner {
  background-color: #1abc9c;
  color: #d1f2eb;
  margin-left: 42px;
  min-height: 316px;
  padding: 0 30px 30px;
}
.footer-banner .footer-title {
  color: #ffffff;
}
.footer-banner a {
  color: #b7f5e9;
  text-decoration: underline;
}
.footer-banner a:hover {
  text-decoration: none;
}
.footer-banner ul {
  list-style-type: none;
  margin: 0 0 26px;
  padding: 0;
}
.footer-banner ul li {
  border-top: 1px solid #1bc5a3;
  line-height: 19px;
  padding: 6px 0;
}
.footer-banner ul li:first-child {
  border-top: none;
  padding-top: 1px;
}
.last-col {
  overflow: hidden;
}
.ptn,
.pvn,
.pan {
  padding-top: 0;
}
.ptx,
.pvx,
.pax {
  padding-top: 3px;
}
.pts,
.pvs,
.pas {
  padding-top: 5px;
}
.ptm,
.pvm,
.pam {
  padding-top: 10px;
}
.ptl,
.pvl,
.pal {
  padding-top: 20px;
}
.prn,
.phn,
.pan {
  padding-right: 0;
}
.prx,
.phx,
.pax {
  padding-right: 3px;
}
.prs,
.phs,
.pas {
  padding-right: 5px;
}
.prm,
.phm,
.pam {
  padding-right: 10px;
}
.prl,
.phl,
.pal {
  padding-right: 20px;
}
.pbn,
.pvn,
.pan {
  padding-bottom: 0;
}
.pbx,
.pvx,
.pax {
  padding-bottom: 3px;
}
.pbs,
.pvs,
.pas {
  padding-bottom: 5px;
}
.pbm,
.pvm,
.pam {
  padding-bottom: 10px;
}
.pbl,
.pvl,
.pal {
  padding-bottom: 20px;
}
.pln,
.phn,
.pan {
  padding-left: 0;
}
.plx,
.phx,
.pax {
  padding-left: 3px;
}
.pls,
.phs,
.pas {
  padding-left: 5px;
}
.plm,
.phm,
.pam {
  padding-left: 10px;
}
.pll,
.phl,
.pal {
  padding-left: 20px;
}
.mtn,
.mvn,
.man {
  margin-top: 0px;
}
.mtx,
.mvx,
.max {
  margin-top: 3px;
}
.mts,
.mvs,
.mas {
  margin-top: 5px;
}
.mtm,
.mvm,
.mam {
  margin-top: 10px;
}
.mtl,
.mvl,
.mal {
  margin-top: 20px;
}
.mrn,
.mhn,
.man {
  margin-right: 0px;
}
.mrx,
.mhx,
.max {
  margin-right: 3px;
}
.mrs,
.mhs,
.mas {
  margin-right: 5px;
}
.mrm,
.mhm,
.mam {
  margin-right: 10px;
}
.mrl,
.mhl,
.mal {
  margin-right: 20px;
}
.mbn,
.mvn,
.man {
  margin-bottom: 0px;
}
.mbx,
.mvx,
.max {
  margin-bottom: 3px;
}
.mbs,
.mvs,
.mas {
  margin-bottom: 5px;
}
.mbm,
.mvm,
.mam {
  margin-bottom: 10px;
}
.mbl,
.mvl,
.mal {
  margin-bottom: 20px;
}
.mln,
.mhn,
.man {
  margin-left: 0px;
}
.mlx,
.mhx,
.max {
  margin-left: 3px;
}
.mls,
.mhs,
.mas {
  margin-left: 5px;
}
.mlm,
.mhm,
.mam {
  margin-left: 10px;
}
.mll,
.mhl,
.mal {
  margin-left: 20px;
}
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  .btn {
    border-style: solid;
    border-width: 2px;
  }
  .dropdown-menu,
  .select2-drop {
    background: #fff !important;
    border: 2px solid #ddd;
  }
  .input-group-rounded .input-group-btn + .form-control,
  .input-group-rounded .input-group-btn + .select2-search input[type="text"],
  .input-group-rounded .input-group-btn + .select2-search input[type="text"] {
    padding-left: 10px;
  }
  .form-control,
  .select2-search input[type="text"] {
    border: 2px solid #ddd !important;
  }
  .bootstrap-switch {
    height: 33px;
    width: 84px;
    border: 2px solid #bdc3c7;
  }
  .tooltip {
    border: 2px solid #bdc3c7;
  }
  .progress,
  .ui-slider {
    background: #ddd !important;
  }
  .progress-bar,
  .ui-slider-range,
  .ui-slider-handle {
    background: #bdc3c7 !important;
  }
}
/*# sourceMappingURL=flat-ui.css.map */