// Button variants
//
.button-variant(@color; @background; @hover-background; @active-background; @disabled-background: @gray-light) {
  color: @color;
  background-color: @background;

  &:hover,
  &.hover,
  &:focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color;
    background-color: @hover-background;
    border-color: @hover-background;
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background: @active-background;
    border-color: @active-background;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &.hover,
    &:focus,
    &:active,
    &.active {
      background-color: @disabled-background;
      border-color: @background;
    }
  }

  .badge {
    color: @background;
    background-color: @inverse;
  }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
}

// Social button variants
.social-button-variant(@color; @background) {
  color: @color;
  background-color: @background;

  &:hover,
  &:focus {
    background-color: mix(@background, white, 80%);
  }
  &:active,
  &.active {
    background-color: mix(@background, black, 85%);
  }
}
