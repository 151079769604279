//
// Tiles
// -------------------------------------------------

.tile {
  background-color: @tiles-bg;
  border-radius: @tiles-border-radius;
  padding: 14px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;

  .tile-hot-ribbon {
    display: block;
    position: absolute;
    right: -4px;
    top: -4px;
    width: 82px;
  }
  p {
    font-size: 15px;
    margin-bottom: 33px;
  }
}
.tile-image {
  height: 100px;
  margin: 31px 0 27px;
  vertical-align: bottom;

  &.big-illustration {
    height: 111px;
    margin-top: 20px;
    width: 112px;
  }
}
.tile-title {
  font-size: 20px;
  margin: 0;
}
