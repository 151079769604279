//
// Palette
// --------------------------------------------------

.pallete-item {
  width: 140px;
  float: left;
  margin: 0 0 20px 20px;
}
.palette {
  font-size: ceil((@component-font-size-base * 0.933)); // ~14px
  line-height: 1.214; // ~17px
  color: @inverse;
  margin: 0;
  padding: 15px;
  text-transform: uppercase;

  dt,
  dd {
    line-height: 1.429;
  }
  dt {
    display: block;
    font-weight: bold;
    opacity: .8;
  }
  dd {
    font-weight: 300;
    margin-left: 0;
    opacity: .8;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

//
// Pallet grid
// --------------------------------------------------
.pallet-variant(~"turquoise", ~"green-sea");
.pallet-variant(~"emerald", ~"nephritis");
.pallet-variant(~"peter-river", ~"belize-hole");
.pallet-variant(~"amethyst", ~"wisteria");
.pallet-variant(~"wet-asphalt", ~"midnight-blue");

.pallet-variant(~"sun-flower", ~"orange");
.pallet-variant(~"carrot", ~"pumpkin");
.pallet-variant(~"alizarin", ~"pomegranate");
.pallet-variant(~"clouds", ~"silver");
.pallet-variant(~"concrete", ~"asbestos");

.palette-clouds {
  color: #bdc3c7;
}

// Palette paragraph
.palette-paragraph {
  color: #7f8c8d;
  font-size: 12px;
  line-height: 17px;

  span {
    color: #bdc3c7;
  }
}

// Headline
.palette-headline {
  color: #7f8c8d;
  font-size: 13px;
  font-weight: 700;
  margin-top: -3px;
}
