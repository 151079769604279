// Select variants
//

.select-variant(@color; @background; @hover-background; @active-background; @disabled-background: @gray-light; @arrow-color) {
  .select2-choice {
    color: @color;
    background-color: @background;

    &:hover,
    &.hover,
    &:focus,
    &:active {
      color: @color;
      background-color: @hover-background;
      border-color: @hover-background;
    }
    &:active {
      background: @active-background;
      border-color: @active-background;
    }
    .select2-container-disabled& {
      &,
      &:hover,
      &:focus,
      &:active {
        background-color: @disabled-background;
        border-color: @background;
      }
    }

    .select2-arrow {
      border-top-color: @arrow-color;
    }
  }
}

.select-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  .button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius);
  padding-right: (@padding-horizontal * 2 + 9px);
  min-height: round((@line-height*@font-size + 2*@padding-vertical)); // we need min-height for empty ones
}

.multiple-select-variant(@background; @hover-background; @border-color) {
  .select2-container-multi& {
      border-color: @border-color;

      .select2-search-choice {
        background-color: @background;

        &:hover {
          background-color: @hover-background;
        }
      }
  }
}
