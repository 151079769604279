//
// Tooltips
// --------------------------------------------------

// Base class
.tooltip {
  font-size: ceil((@component-font-size-base * 0.933)); // ~14px
  line-height: 1.286; // 18px
  z-index: @zindex-tooltip;

  &.in     { .opacity(@tooltip-opacity); }
  &.top    { margin-top:  -5px; padding: @tooltip-arrow-width 0; }
  &.right  { margin-left:  5px; padding: 0 @tooltip-arrow-width; }
  &.bottom { margin-top:   5px; padding: @tooltip-arrow-width 0; }
  &.left   { margin-left: -5px; padding: 0 @tooltip-arrow-width; }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: @tooltip-max-width;
  line-height: 1.286; // 18px
  padding: 12px 12px;
  color: @tooltip-color;
  background-color: @tooltip-bg;
  border-radius: @border-radius-large;
}

// Arrows
.tooltip {
  &.top .tooltip-arrow {
    margin-left: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
    border-top-color: @tooltip-arrow-color;
  }
  &.right .tooltip-arrow {
    margin-top: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width @tooltip-arrow-width 0;
    border-right-color: @tooltip-arrow-color;
  }
  &.left .tooltip-arrow {
    margin-top: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width 0 @tooltip-arrow-width @tooltip-arrow-width;
    border-left-color: @tooltip-arrow-color;
  }
  &.bottom .tooltip-arrow {
    margin-left: -@tooltip-arrow-width;
    border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
    border-bottom-color: @tooltip-arrow-color;
  }
}
