// Should be used to modify the default spacing between objects (not between nodes of * the same object)
// p,m = padding,margin
// a,t,r,b,l,h,v = all,top,right,bottom,left,horizontal,vertical
// x,s,m,l,n = extra-small(@x),small(@s),medium(@m),large(@l),none(0px)
@x: 3px;
@s: 5px;
@m: 10px;
@l: 20px;

.last-col {
  overflow: hidden;
}

.ptn, .pvn, .pan {
  padding-top: 0;
}

.ptx, .pvx, .pax {
  padding-top: @x;
}

.pts, .pvs, .pas {
  padding-top: @s;
}

.ptm, .pvm, .pam {
  padding-top: @m;
}

.ptl, .pvl, .pal {
  padding-top: @l;
}

.prn, .phn, .pan {
  padding-right: 0;
}

.prx, .phx, .pax {
  padding-right: @x;
}

.prs, .phs, .pas {
  padding-right: @s;
}

.prm, .phm, .pam {
  padding-right: @m;
}

.prl, .phl, .pal {
  padding-right: @l;
}

.pbn, .pvn, .pan {
  padding-bottom: 0;
}

.pbx, .pvx, .pax {
  padding-bottom: @x;
}

.pbs, .pvs, .pas {
  padding-bottom: @s;
}

.pbm, .pvm, .pam {
  padding-bottom: @m;
}

.pbl, .pvl, .pal {
  padding-bottom: @l;
}

.pln, .phn, .pan {
  padding-left: 0;
}

.plx, .phx, .pax {
  padding-left: @x;
}

.pls, .phs, .pas {
  padding-left: @s;
}

.plm, .phm, .pam {
  padding-left: @m;
}

.pll, .phl, .pal {
  padding-left: @l;
}

.mtn, .mvn, .man {
  margin-top: 0px;
}

.mtx, .mvx, .max {
  margin-top: @x;
}

.mts, .mvs, .mas {
  margin-top: @s;
}

.mtm, .mvm, .mam {
  margin-top: @m;
}

.mtl, .mvl, .mal {
  margin-top: @l;
}

.mrn, .mhn, .man {
  margin-right: 0px;
}

.mrx, .mhx, .max {
  margin-right: @x;
}

.mrs, .mhs, .mas {
  margin-right: @s;
}

.mrm, .mhm, .mam {
  margin-right: @m;
}

.mrl, .mhl, .mal {
  margin-right: @l;
}

.mbn, .mvn, .man {
  margin-bottom: 0px;
}

.mbx, .mvx, .max {
  margin-bottom: @x;
}

.mbs, .mvs, .mas {
  margin-bottom: @s;
}

.mbm, .mvm, .mam {
  margin-bottom: @m;
}

.mbl, .mvl, .mal {
  margin-bottom: @l;
}

.mln, .mhn, .man {
  margin-left: 0px;
}

.mlx, .mhx, .max {
  margin-left: @x;
}

.mls, .mhs, .mas {
  margin-left: @s;
}

.mlm, .mhm, .mam {
  margin-left: @m;
}

.mll, .mhl, .mal {
  margin-left: @l;
}
